<template lang="pug">
.app-logo
  //- img.app-icon(src='../../assets/icon.png')
  .app-title {{ currentDistributorName }}
</template>

<script>
import auth from '@/mixins/auth.mixin'

export default {
  mixins: [auth],
  computed: {
    currentDistributorName () {
      const name = this.currentDistributor && this.currentDistributor.name
      if (name) {
        document.title = name
      }
      return name
    }
  }
}
</script>

<style lang="scss" scoped>
.app-logo {
  height: 100%;
  display: flex;
  align-items: center;
}

.app-icon {
  height: 30px;
  margin-right: 10px;
}
</style>
