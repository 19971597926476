<template lang="pug">
b-tooltip(:label='direction(device)' :delay='tooltipOptions.delay' :position='tooltipOptions.position.default' :type='tooltipOptions.type.default')
  .direction
    b-icon.hand-stop(v-if='isStopped(device.speed)' pack='fa' icon='hand-paper')
    b-icon(v-else pack='fa' icon='arrow-up' :style='{ transform: `rotate(${device.acimut}deg)` }')
</template>

<script>
import { tooltipOptions } from '@/constants/configs'
import { deviceIsStopped, getDeviceDirection } from '@/constants/utils'

export default {
  props: {
    device: { type: Object }
  },
  data () {
    return {
      tooltipOptions
    }
  },
  methods: {
    isStopped (speed) {
      return deviceIsStopped(speed)
    },
    direction (device) {
      const { speed, acimutDirection } = device
      return getDeviceDirection(speed, acimutDirection)
    }
  }
}
</script>
