import { formatDistanceStrict } from 'date-fns'
import { es } from 'date-fns/locale'

export default function (value, addSuffix = false) {
  if (!value) return ''
  if (typeof value === 'string') {
    return formatDistanceStrict(new Date(value), new Date(), { locale: es, addSuffix })
  }
  return formatDistanceStrict(value, new Date())
}
