import { intervalToDuration } from 'date-fns'

export default function (value, addSuffix = false) {
  if (!value) return ''

  const duration = intervalToDuration({ start: 0, end: value * 60 * 1000 })
  let parts = [duration.years, duration.months, duration.days, duration.hours, duration.minutes, duration.seconds]
  let labelParts = ['a', 'm', 'd', 'h', 'm', 's']

  const lastNonZeroIndex = parts.findIndex(part => part !== 0)
  const filteredParts = parts.slice(lastNonZeroIndex)
  const filteredLabelParts = labelParts.slice(lastNonZeroIndex)

  return filteredParts.map((number, idx) => `${number}${filteredLabelParts[idx]}`).join(' ')
}
