import Vue from 'vue'
import Buefy from 'buefy'
import VueEditableGrid from 'vue-editable-grid'

import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import toast from './services/toast.service'
import { Icon } from 'leaflet'
import 'leaflet/dist/leaflet.css'
import DefaultLayout from '@/layouts/Default'
import PublicLayout from '@/layouts/Public'
import InpointLayout from '@/layouts/Inpoint'
import BasicLayout from '@/layouts/Basic'
import Modal from '@/components/shared/Modal'
import ModalCard from '@/components/shared/ModalCard'
import Field from '@/components/shared/Field'
import AppForm from '@/components/shared/AppForm'
import AppSelect from '@/components/shared/AppSelect'
import AppDate from '@/components/shared/AppDate'
import CollapseCard from '@/components/shared/CollapseCard'
import PageTitle from '@/components/shared/PageTitle'

import CheckField from '@/components/shared/forms/CheckField'
import DateTimeField from '@/components/shared/forms/DateTimeField.vue'
import TimeField from '@/components/shared/forms/TimeField'
import FileField from '@/components/shared/forms/FileField'
import SelectField from '@/components/shared/forms/SelectField'
import TextField from '@/components/shared/forms/TextField'
import ColorField from './components/shared/forms/ColorField'
import ExportButton from '@/components/shared/ExportButton'

import './icons'
import 'vue-editable-grid/dist/VueEditableGrid.css'

import datetimeFilter from '@/filters/datetime.filter'
import dateFilter from '@/filters/date.filter'
import currencyFilter from '@/filters/currency.filter'
import percentFilter from '@/filters/percent.filter'
import dateDistanceFilter from './filters/dateDistance.filter'
import title from './directives/title'

Vue.config.productionTip = false

Vue.prototype.$toast = toast

Vue.use(Buefy, {
  defaultIconComponent: 'icon',
  defaultIconPack: 'fas'
})

// this part resolve an issue where the markers would not appear
delete Icon.Default.prototype._getIconUrl

Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png')
})

Vue.component('default-layout', DefaultLayout)
Vue.component('public-layout', PublicLayout)
Vue.component('inpoint-layout', InpointLayout)
Vue.component('basic-layout', BasicLayout)
Vue.component('modal', Modal)
Vue.component('modal-card', ModalCard)
Vue.component('field', Field)
Vue.component('app-select', AppSelect)
Vue.component('app-date', AppDate)
Vue.component('app-form', AppForm)
Vue.component('collapse-card', CollapseCard)
Vue.component('page-title', PageTitle)

Vue.component('check-field', CheckField)
Vue.component('date-time-field', DateTimeField)
Vue.component('time-field', TimeField)
Vue.component('file-field', FileField)
Vue.component('select-field', SelectField)
Vue.component('text-field', TextField)
Vue.component('color-field', ColorField)
Vue.component('export-button', ExportButton)
Vue.component('vue-editable-grid', VueEditableGrid)

Vue.directive('title', title)

Vue.filter('datetime', datetimeFilter)
Vue.filter('date', dateFilter)
Vue.filter('currency', currencyFilter)
Vue.filter('percent', percentFilter)
Vue.filter('dateDistance', dateDistanceFilter)

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
