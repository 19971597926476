<template lang="pug">
div
  b-loading(:is-full-page='true' :active.sync='loading')
  list-tools(@reload='fetchLocations' @check='toggleCheck' @remove='remove' :allow-to-remove='!!selected' :disabled='loading || deleting')
  div
    p(v-if='!loading && !groups.length').text-center No hay sitios creadas
    .item-group(v-for='group in groups' :key='group')
      button.item-group-name(@click='toggleGroup(group)')
        span {{ group || 'Sin agrupar' }}
        span.count {{itemsByGroup[group].length}}
      .item-list(v-if='groupsOpened[group]')
        .item(
          v-for='item in itemsByGroup[group]'
          :key='item.id'
          :class='{ selected: selected && selected.id === item.id }'
          @click='select(item)'
        )
          b-tooltip(
            v-if='allowCheck'
            label='Mostrar u ocultar del mapa'
            :delay='tooltipOptions.delay'
            :position='tooltipOptions.position.default'
            :type='tooltipOptions.type.default'
          )
            label.info-item.in-map
              input(type='checkbox' v-model='item.inMap' @dblclick='thisInMap(item)')
          .info-item.name {{ item.name }}
          b-button(size='is-small' @click='editLocation(item)')
            b-icon(pack='fa' icon='edit')
</template>

<script>
import { mapActions, mapState } from 'vuex'
import ListTools from './ListTools'
import localStorage from '@/database/local-storage'
import { tooltipOptions } from '@/constants/configs'
import toastService from '@/services/toast.service'
import { onLocationEdit } from '../locations/create-location.modal'

export default {
  components: { ListTools },
  props: {
    allowCheck: { type: Boolean },
    locations: { type: Array }
  },
  data () {
    return {
      groupsOpened: {},
      tooltipOptions,
      selected: null
    }
  },
  async created () {
    this.fetchLocations()
    const groupsOpened = await localStorage.getItem('locationsToggleStatus')
    if (groupsOpened) {
      this.groupsOpened = groupsOpened
    }
  },
  computed: {
    ...mapState({
      loading: state => state.locations.loading,
      deleting: state => state.locations.deleting,
      error: state => state.locations.error,
      allLocations: state => state.locations.locations
    }),
    groupBy () {
      return 'group'
    },
    toShowItems () {
      return this.locations || this.allLocations
    },
    groups () {
      return this.toShowItems.reduce((groups, item) => {
        const groupName = item[this.groupBy]
        if (!groups.includes(groupName)) {
          groups.push(groupName)
        }
        return groups
      }, [])
    },
    itemsByGroup () {
      return this.toShowItems.reduce((memory, item) => {
        const groupName = item[this.groupBy]
        if (!memory[groupName]) {
          memory[groupName] = []
        }
        memory[groupName].push(item)
        return memory
      }, {})
    }
  },
  watch: {
    error () {
      if (this.error) {
        this.$toast.error(this.error)
      }
    }
  },
  methods: {
    ...mapActions({
      getLocations: 'locations/getLocations',
      removeLocation: 'locations/removeLocation',
      setCenter: 'map/setCenter',
      setZoom: 'map/setZoom',
      setFocus: 'map/setFocus'
    }),
    fetchLocations () {
      this.getLocations({ hasLocation: true })
    },
    async remove () {
      if (!await toastService.confirm('¿Desea eliminar este sitio permanentemente?')) return
      const result = await this.removeLocation(this.selected)
      if (result) {
        toastService.show(`Sitio ${this.selected.name} eliminado`)
        this.selected = null
      }
    },
    toggleCheck () {
      const inMap = !this.toShowItems.some(d => d.inMap)
      this.toShowItems.forEach(location => {
        location.inMap = inMap
      })
    },
    thisInMap (location) {
      if (location.inMap) {
        this.toShowItems.forEach(_location => {
          _location.inMap = false
        })
        location.inMap = true
        this.setZoom(12)
      }
    },
    select (location) {
      if (!location.inMap) location.inMap = true
      if (location.inMap) {
        this.setFocus({ center: location.latLng })
        this.selected = location
      }
    },
    async toggleGroup (groupName) {
      const newValue = !this.groupsOpened[groupName]
      this.$set(this.groupsOpened, groupName, newValue)
      await localStorage.setItem('locationsToggleStatus', this.groupsOpened)
    },
    getIgnitionClass (ignition) {
      if (ignition === null) return ''
      return `ignition-${ignition ? 'on' : 'off'}`
    },
    getIgnitionText (ignition) {
      if (ignition === null) return 'No se tiene información de ignición'
      return ignition ? 'Encendido' : 'Apagado'
    },
    editLocation (location) {
      onLocationEdit(location, this)
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~@/scss/variables/colors';
@import '~@/scss/variables/sizes';

.item-group-name {
  padding: $base-padding;
  background: #dfdfdf;
  border: none;
  border-bottom: solid 1px #bcbcbc;
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 30px;
  width: 100%;

  @media only screen and (max-width: 900px) {
    min-height: 50px;
  }

  .count {
    font-size: 0.8em;
  }
}

.item {
  display: grid;
  grid-template-columns: 30px auto 30px;
  min-height: 30px;
  cursor: pointer;
  align-items: center;
  padding: 2px 0;

  @media only screen and (max-width: 900px) {
    min-height: 50px;
  }

  &.selected {
    background-color: $white;
  }

  &:hover {
    background: $white;
  }
}

.ignition-on {
  color: $yellow;
}

.ignition-off {
  color: $black;
}

.hand-stop {
  color: $bad-red;
}

.info-item {
  height: 100%;
  display: flex;
  align-items: center;
}

.selected .info-item.name {
  font-weight: 700;
}

.in-map,
.status {
  justify-content: center;
}
</style>
