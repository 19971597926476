const createEvent = (emitAllListeners) => ({
  listeners: [],
  dispatch () {
    if (this.listeners.length) {
      if (emitAllListeners) {
        const args = [...arguments]
        this.listeners.forEach(listener => listener(...args))
      } else {
        this.listeners[this.listeners.length - 1](...arguments)
      }
    }
  },
  on (callback) {
    this.listeners.push(callback)

    return {
      unsubscribe: () => {
        const index = this.listeners.indexOf(callback)
        if (index >= 0) {
          this.listeners.splice(index, 1)
        }
      }
    }
  }
})

export const navbarStatusChanged = createEvent(true)
