export const tooltipOptions = {
  delay: 500,
  type: {
    black: 'is-black',
    dark: 'is-dark',
    info: 'is-info',
    light: 'is-light',
    primary: 'is-primary',
    success: 'is-success',
    warning: 'is-warning',
    white: 'is-white',
    default: 'is-dark'
  },
  position: {
    bottom: 'is-bottom',
    left: 'is-left',
    right: 'is-right',
    top: 'is-top',
    default: 'is-bottom'
  },
  size: {
    large: 'is-large',
    medium: 'is-medium',
    small: 'is-small',
    default: 'is-medium'
  }
}

export const mapPolylineColors = [
  '#3366cc',
  '#dc3912',
  '#ff9900',
  '#109618',
  '#990099',
  '#0099c6',
  '#dd4477',
  '#66aa00',
  '#b82e2e',
  '#3366cc',
  '#994499',
  '#22aa99',
  '#aaaa11',
  '#6633cc',
  '#e67300',
  '#8b0707',
  '#651067',
  '#329262',
  '#5574a6',
  '#3b3eac',
  '#b77322',
  '#16d620',
  '#b91383',
  '#f4359e',
  '#9c5935',
  '#a9c413',
  '#2a778d',
  '#668d1c',
  '#bea413',
  '#0c5922',
  '#743411'
]

export const rangeInHoursForDeviceReports = 24
