let componentInstance = null

export const openCommandModal = async ({ props, parent, modal }) => {
  if (!componentInstance) {
    const component = await import(/* webpackChunkName: "command" */ '@/views/command/Command.vue')
    componentInstance = component.default
  }

  modal.open({
    component: componentInstance,
    parent,
    hasModalCard: true,
    trapFocus: true,
    props
  })
}
