<template lang="pug">
.inpoint-page
  .page-header
    span.page-user {{ currentUser.names }}
    h1.page-title InPoint
    a.header-button(title='Salir' @click='tryLogout')
      icon(icon='sign-out-alt')
  .page-body
    slot
</template>

<script>
import toastService from '@/services/toast.service'
import { mapActions } from 'vuex'
import authMixin from '@/mixins/auth.mixin'

export default {
  mixins: [authMixin],
  methods: {
    ...mapActions({
      logout: 'auth/logout'
    }),
    tryLogout () {
      toastService.confirm('¿Desea cerrar sesión?', () => {
        this.logout()
      })
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~@/scss/variables/colors';
$header-size: 50px;
$header-margins: 10px;

body,
html {
  height: 100%;
  overflow: hidden;
}

.inpoint-page {
  display: grid;
  grid-template-rows: $header-size auto;
  flex: 1;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: #fff;
}

.page-header {
  background: $main-color;
  color: white;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.page-title {
  margin: 0;
  font-size: 1.1em;
}

.page-user {
  padding: $header-margins;
}

.page-body {
  flex: 1;
  overflow-y: auto;
}

.header-button {
  height: 100%;
  width: $header-size;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
