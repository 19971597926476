
// Function to calculate distance using the Haversine formula
function haversineDistance (coord1, coord2) {
  const R = 6371 // Earth ratio in KMs
  const [lat1, lon1] = [coord1.lat, coord1.lng]
  const [lat2, lon2] = [coord2.lat, coord2.lng]

  const dLat = (lat2 - lat1) * Math.PI / 180
  const dLon = (lon2 - lon1) * Math.PI / 180

  const a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(lat1 * Math.PI / 180) * Math.cos(lat2 * Math.PI / 180) *
      Math.sin(dLon / 2) * Math.sin(dLon / 2)

  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a))

  return R * c
}

export function findClosestPointIndex (latLngs, referencePoint) {
  let closestPointIndex = null
  let minDistance = Infinity

  latLngs.forEach((point, idx) => {
    const distance = haversineDistance(referencePoint, point)
    if (distance < minDistance) {
      minDistance = distance
      closestPointIndex = idx
    }
  })

  return closestPointIndex
}
