const state = () => ({
  url: 'http://{s}.tile.osm.org/{z}/{x}/{y}.png',
  zoom: 17, // 10
  // center: [6.1967846, -75.5749092],
  center: [1.2040155555555556, -77.2846561111111],
  options: { zoomControl: false },
  followId: null,
  markerFocus: null
})

const mutations = {
  changeZoom: (state, zoom) => {
    state.zoom = zoom
  },
  changeCenter: (state, center) => {
    state.center = center
  },
  changeFollowId: (state, id) => {
    state.followId = id
  },
  changeMarkerFocus: (state, markerFocus) => {
    state.markerFocus = markerFocus
  }
}

const actions = {
  setZoom: async ({ commit }, zoom) => {
    commit('changeZoom', zoom)
  },
  setCenter: async ({ commit }, center) => {
    commit('changeCenter', center)
  },
  setFocus: async ({ commit }, { center, zoom }) => {
    await commit('changeZoom', zoom || 17)
    await commit('changeCenter', [...center])
  },
  setFollowId: async ({ commit }, followId) => {
    await commit('changeFollowId', followId)
  },
  setMarkerFocus: async ({ commit }, { center, zoom, icon, location }) => {
    await commit('changeMarkerFocus', { latLng: [...center], icon, location })
    await commit('changeZoom', zoom || 17)
    await commit('changeCenter', [...center])
  },
  removeMarkerFocus: async ({ commit }) => {
    await commit('changeMarkerFocus', null)
  }
}

export default {
  state,
  mutations,
  actions,
  namespaced: true
}
