<template lang="pug">
li.navbar-item-container
  router-link.navbar-item-content(:to='{ name: item.name }' :title='item.meta.title')
    span.navbar-item-icon
      icon(:icon='item.meta.icon')
    span.navbar-item-text {{ item.meta.title }}
  ul.second-navbar-container(v-if='item.items && item.items.length' :class='{ secondary: isSecondary }')
    menu-item(
      v-for='menuItem in item.items'
      :item='menuItem'
      :key='menuItem.name'
      is-secondary
    )
</template>

<script>
export default {
  components: {
    MenuItem: () => import('./MenuItem.vue')
  },
  props: {
    item: { type: Object, required: true },
    isSecondary: { type: Boolean, default: false }
  }
}
</script>

<style lang="scss" scoped>
@import '~@/scss/variables/sizes';
@import '~@/scss/variables/colors';
$submenu-width: 200px;

.navbar-item-container {
  display: flex;
  flex-direction: column;
  position: relative;

  &.reduced {
    .navbar-item-content {
      .navbar-item-text {
        display: none;
      }
    }
  }
}

.navbar-item-content {
  align-items: center;
  display: flex;
  flex: 1;
  height: 100%;
  padding: 10px 10px;
  color: inherit;
  border-left: solid 2px transparent;
  position: relative;
  overflow: hidden;

  &::before {
    content: '';
    position: absolute;
    width: 20px;
    height: 20px;
    background: $menu-item-hover-border-color;
    left: -25px;
    transform: rotate(45deg);
    transition: left 0.2s ease-in-out;
  }

  &:hover {
    background: $menu-item-hover-bg;
    border-left-color: $menu-item-hover-border-color;

    &::before {
      left: -17px;
    }
  }
}

.navbar-item-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0.5;
  width: 16px;
}

.navbar-item-text {
  margin-left: 8px;
}

.second-navbar-container {
  background: white;
  display: flex;
  flex-direction: column;

  &.secondary .navbar-item-content {
    padding-left: 20px;
  }
}
</style>
