<template lang="pug">
b-button(@click='download' :disabled='disabled' :size='size' :icon-right='showIcon ? "download" : ""')
  span(v-if='!hideLabel') Exportar a Excel
</template>

<script>
import xlsx from 'xlsx'

export default {
  props: {
    data: { type: Array, required: true },
    columns: { type: Array, required: true },
    name: { type: String },
    size: { type: String, default: '' },
    disabled: { type: Boolean, default: false },
    showIcon: { type: Boolean, default: false },
    hideLabel: { type: Boolean, default: false }
  },
  methods: {
    download () {
      const data = []
      data.push(this.columns.map(col => col.headerName))
      this.data.forEach(row => {
        data.push(this.columns.map(col => row[col.field]))
      })
      const worksheet = xlsx.utils.aoa_to_sheet(data)
      const newWorkbook = xlsx.utils.book_new()
      xlsx.utils.book_append_sheet(newWorkbook, worksheet, 'SheetJS')
      xlsx.writeFile(newWorkbook, `${this.name || 'grid export'}.xlsx`)
    }
  }
}
</script>
