let componentInstance = null

export const openCreateZoneModal = async ({ props, parent, modal }) => {
  if (!componentInstance) {
    const component = await import(/* webpackChunkName: "create-zone-form" */ './CreateZoneForm.vue')
    componentInstance = component.default
  }

  modal.open({
    component: componentInstance,
    parent,
    hasModalCard: true,
    trapFocus: true,
    canCancel: ['escape', 'x'],
    props
  })
}

export const onZoneDrawn = (latLngs, componentInstance) => {
  openCreateZoneModal({
    modal: componentInstance.$buefy.modal,
    parent: componentInstance,
    props: {
      latLngs,
      isModal: true
    }
  })
}

export const onZoneEdit = (zoneUpdated, componentInstance) => {
  openCreateZoneModal({
    modal: componentInstance.$buefy.modal,
    parent: componentInstance,
    props: {
      zoneUpdated
    }
  })
}
