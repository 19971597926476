<template lang="pug">
.map
    .editing-container(v-if='editModeEnabled')
      b-button.button.is-small.is-primary.mr-5(type='button' :disabled='!canSave' :loading='saving' @click='submitEdited') Guardar
      b-button.button.is-small.is-danger(type='button' :disabled='saving' @click='cancelEditMode') Cancelar
    l-map(
      style="height: 100%; width: 100%"
      ref='map'
      :zoom='zoom'
      :center='center'
      :options='options'
      @ready="mapIsReady"
      @update:zoom='zoomUpdated'
      @update:center='centerUpdated'
      @update:bounds='boundsUpdated'
      @update:movestart='centerUpdated'
    )
      l-draw-toolbar(position="bottomleft")
      //- l-control(position='bottomleft')
      //- l-tile-layer(:url='url')
      l-control-layers(position='bottomright')
      l-tile-layer(
        v-for='tileProvider in tileProviders',
        :key='tileProvider.name',
        :name='tileProvider.name',
        :visible='tileProvider.visible',
        :url='tileProvider.url',
        :attribution='tileProvider.attribution',
        layer-type='base'
      )
      l-control(position='bottomleft')
        map-controls
      l-polygon(
        v-for='zone in inMapZones'
        :key='`zone${zone.id}`'
        :lat-lngs='zone.latLngs'
        :color='zone.backgroundColor'
        :fill-color='zone.backgroundColor'
        @click="zoneClicked($event, zone)"
        @edit="zoneEdited($event, zone)"
      )
      l-marker(
        v-for='location in inMapLocations'
        v-if='location.inMap'
        :key='`location${location.id}`'
        :lat-lng='location.latLng'
        :icon='location.markerIcon'
        :draggable='markerEditableId === location.id'
        @click="locationClicked($event, location)"
        @dragend="locationEdited($event, location)"
      )
        l-tooltip {{ location.name }}
      l-marker(
        :key='`device${device.id}`'
        :lat-lng='device.latLng'
        :icon='device.LIcon'
        @click='deviceMarkerClicked(device)'
        v-for='device in devicesWithLocation'
        v-if='device.inMap'
      )
        l-tooltip {{ device.name }}
      l-polyline(
        :lat-lngs='latestPoints'
        color='blue'
        :weight='3'
        v-if='latestPoints.length'
      )
      template(v-for='report in reports')
        l-polyline(
          :key='`report${report.id}`'
          :lat-lngs='report.latlngs'
          :color='report.color'
          :weight='5'
          :options='{ id: report.id }'
          @click='reportLineClicked'
        )
      l-marker(:lat-lng='markerFocus.latLng' v-if='markerFocus')
        l-icon(
          :icon-size='[16, 16]'
          :icon-url='markerFocus.icon'
          v-if='markerFocus.icon'
        )
        device-map-popup(
          :date='markerFocus.location.reportDate'
          :speed='String(markerFocus.location.speed)'
          :battery='String(markerFocus.location.battery)'
          :isStopped='markerFocus.location.isStopped'
          :stoppedMinutes='markerFocus.location.stoppedMinutes'
          :nearbyLocationName='markerFocus.location.nearbyLocation && markerFocus.location.nearbyLocation.item'
          :nearbyLocationDistance='markerFocus.location.nearbyLocation && String(markerFocus.location.nearbyLocation.distance)'
        )
</template>

<script>
import Vue from 'vue'
import { LMap, LTileLayer, LMarker, LControl, LControlLayers, LPolygon, LPolyline, LCircleMarker, LIcon, LTooltip } from 'vue2-leaflet'
import LDrawToolbar from 'vue2-leaflet-draw-toolbar'
import { mapState, mapActions, mapGetters } from 'vuex'
import MapControls from '@/components/shared/MapControls'
import DeviceMapPopup from '@/components/devices/DeviceMapPopup.vue'
import devicesService from '@/services/devices.service'
import icoInfo from '@/assets/icons/info.svg'
import icoStop from '@/assets/icons/cancel.svg'
import { onZoneDrawn } from '@/components/zones/create-zone.modal.js'
import { onLocationDrawn } from '@/components/locations/create-location.modal.js'
import { tileProviders } from './constant'
import { findClosestPointIndex } from './main-map-reports'
import { Editable } from './editable'

let userInteracton = false

export default {
  components: { LMap, LTileLayer, LMarker, LControl, LControlLayers, MapControls, LPolygon, LPolyline, LCircleMarker, LIcon, DeviceMapPopup, LDrawToolbar, LTooltip },
  data () {
    return {
      // url: 'http://{s}.tile.osm.org/{z}/{x}/{y}.png',
      // zoom: 16,
      // center: [6.1967846, -75.5749092],
      bounds: null,
      mapInstance: null,
      tileProviders: tileProviders,
      editable: null,
      saving: false,
      zoneIdToHide: null,
      markerEditableId: null
    }
  },
  async mounted () {
    this.$nextTick(() => {
      this.mapInitialized(this.$refs.map.mapObject)
    })
  },
  computed: {
    ...mapState({
      devices: state => state.devices.devices,
      latestPoints: state => state.devices.latestPoints,
      url: state => state.map.url,
      zoom: state => state.map.zoom,
      center: state => state.map.center,
      markerFocus: state => state.map.markerFocus,
      options: state => state.map.options
    }),
    ...mapGetters({
      reports: 'reports/selectedReports',
      inMapLocations: 'locations/inMapLocations'
    }),
    inMapZones () {
      const zones = this.$store.getters['zones/inMapZones']
      if (this.zoneIdToHide) {
        return zones.filter(zone => zone.id !== this.zoneIdToHide)
      }
      return zones
    },
    editModeEnabled () {
      return !!this.editable
    },
    canSave () {
      return this.editable && this.editable.touched
    },
    devicesWithLocation () {
      return devicesService.filterWithLocation(this.devices)
    }
  },
  methods: {
    ...mapActions({
      setCenter: 'map/setCenter',
      goToReportLocation: 'reports/goToLocation'
    }),
    mapIsReady () {},
    reportLineClicked (event) {
      const target = event.target
      const latLngs = target.getLatLngs()
      const idx = findClosestPointIndex(latLngs, event.latlng)
      const report = this.reports.find(r => r.id === target.options.id)
      const location = report.locations[idx]
      this.goToReportLocation({ location, report })
    },
    mapInitialized (instance) {
      this.mapInstance = instance
      instance.on('mousedown', this.userInteractionStarted.bind(this))
      instance.on('touchstart', this.userInteractionStarted.bind(this))
      instance.on('mouseup', this.userInteractionEnded.bind(this))
      instance.on('touchend', this.userInteractionEnded.bind(this))
      instance.on('movestart', this.moveStarted.bind(this))
      instance.on('moveend', this.moveEnded.bind(this))
      instance.on('draw:created', this.drawCreated.bind(this))
    },
    userInteractionStarted () {
      userInteracton = true
    },
    userInteractionEnded () {
      userInteracton = false
    },
    moveStarted (event) {
      if (userInteracton) {
        this.userMoveStarted(event)
      }
    },
    moveEnded (event) {
      const { lat, lng } = event.target.getCenter()
      this.setCenter([lat, lng])
    },
    drawCreated ({ layerType, layer }) {
      if (layerType === 'polygon') {
        onZoneDrawn(layer.getLatLngs()[0], this)
      } else if (layerType === 'marker') {
        onLocationDrawn(layer.getLatLng(), this)
      }
      this.$nextTick(() => {
        layer.remove()
      })
    },
    userMoveStarted (event) {
      this.$emit('user-move-started', event)
    },
    zoomUpdated (zoom) {
      // this.zoom = zoom
    },
    centerUpdated (center) {
      // this.center = center
    },
    boundsUpdated (bounds) {
      // this.bounds = bounds
    },
    deviceMarkerClicked (device) {
      this.$emit('device-marker-clicked', device)
    },
    getUrlIcon (isStopped) {
      return isStopped ? icoStop : icoInfo
    },
    zoneClicked ({ target }, zone) {
      this.setEditMode({
        target,
        zone
      })
    },
    locationClicked ({ target }, location) {
      this.setEditMode({
        target,
        location
      })
    },
    zoneEdited () {
      this.editable.markAsTouched()
    },
    locationEdited () {
      this.editable.markAsTouched()
    },
    setEditMode ({ target, zone, location }) {
      if (!this.editModeEnabled) {
        this.editable = new Editable({ target, zone, location })
        if (location) {
          this.markerEditableId = location.id
        }
      }
    },
    async submitEdited () {
      this.saving = true
      await this.editable.submit()
      if (this.editable.zone) {
        await this.redrawZone(this.editable.zone.id)
      }
      this.editable = null
      this.saving = false
    },
    async cancelEditMode () {
      this.editable.cancel()
      if (this.editable.location) {
        this.markerEditableId = null
      }
      if (this.editable.zone) {
        await this.redrawZone(this.editable.zone.id)
      }
      this.editable = null
    },
    async redrawZone (zoneId) {
      // TODO: Needed to re-draw after using edit mode
      this.zoneIdToHide = zoneId
      await Vue.nextTick()
      this.zoneIdToHide = null
    }
  }
}
</script>

<style lang="scss" scoped>
.map {
  width: 100%;
  height: 100%;
}

.editing-container {
  position: absolute;
  z-index: 401;
  right: 0;
  left: 0;
  background: #00000059;
  padding: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
