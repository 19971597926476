import { setToken } from '@/services'
import authService from '@/services/auth.service'
import router from '@/router'

const state = () => ({
  user: null,
  status: ''
})

const mutations = {
  // [AUTH_REQUEST]: (state) => {
  //   state.status = 'loading'
  // },
  setCurrentUser: (state, currentUser) => {
    state.status = 'success'
    state.user = currentUser
  },
  authClosed: (state) => {
    state.status = 'closed'
    state.user = null
    setToken(null)
  }
  // [AUTH_ERROR]: (state) => {
  //   state.status = 'error'
  // }
}

const actions = {
  login: async ({ commit }, user) => {
    try {
      const result = await authService.login(user)
      if (!result) return
      if (result.needPassword) return result
      await setToken(result.token)
      const userData = await authService.me()
      if (!userData) return
      commit('setCurrentUser', userData)
      return userData
    } catch (err) {
      commit('setCurrentUser', null)
      await setToken(null)
    }
  },
  logout: async ({ commit }) => {
    commit('authClosed')
    await setToken(null)
    router.push({ name: 'login' })
  },
  getMe: async ({ commit }) => {
    try {
      const result = await authService.me()
      if (result) {
        commit('setCurrentUser', result)
      }
      return result
    } catch (err) {
      commit('setCurrentUser', null)
      await setToken(null)
    }
  }
}

const getters = {
  isAuth: state => state.user ? !!state.user.token : false,
  currentUser: state => state.user,
  authStatus: state => state.status,
  client: state => state.user && state.user.client,
  distributor: state => state.user && state.user.client && state.user.client.distributor,
  permissions: state => {
    const permissions = (state.user && state.user.permissionsObj) || {}
    // Convert every permission key to cammelCase
    return Object.keys(permissions).reduce((memo, key) => {
      memo[`${key[0].toLowerCase()}${key.substring(1)}`] = permissions[key]
      return memo
    }, {})
  }
}

export default {
  // We're using namespacing
  // in all of our modules.
  // namespaced: true,
  state,
  mutations,
  actions,
  getters,
  namespaced: true
}
