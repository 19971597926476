import locationsService from '@/services/locations.service'
import { icon } from 'leaflet'

const state = () => ({
  loading: false,
  deleting: false,
  error: null,
  locations: []
})

const normalizeItem = item => {
  item.inMap = true
  item.latLng = [item.lat, item.lng]
  item.markerIcon = icon({
    iconUrl: `img/marker-images/${item.icon}.png`,
    iconSize: [32, 37]
  })
  return item
}

const mutations = {
  setLocations: (state, locations) => {
    state.locations = locations
  },
  setStatus: (state, { loading, deleting, error }) => {
    state.loading = loading
    state.deleting = deleting
    state.error = error
  }
}

const actions = {
  getLocations: async ({ commit }, params) => {
    commit('setStatus', { loading: true })
    const result = await locationsService.getAll(params)
    if (result) {
      const locations = result
      locations.forEach(item => normalizeItem(item))
      commit('setLocations', locations)
      commit('setStatus', { loading: false })
    } else {
      commit('setLocations', [])
      commit('setStatus', { loading: false, error: 'Error cargando sitios TODO' })
    }
  },
  saveLocation: async ({ commit, state }, location) => {
    const result = await locationsService.save(location)
    if (result) {
      if (location.id) {
        const editedLocation = state.locations.find(l => l.id === location.id)
        Object.assign(editedLocation, location)
        normalizeItem(editedLocation)
        commit('setLocations', [...state.locations])
      } else {
        const sites = [...state.locations, normalizeItem(result)]
        commit('setLocations', sites)
      }
      return result
    } else {
      return result
    }
  },
  removeLocation: async ({ commit, state }, location) => {
    commit('setStatus', { deleting: true })
    const result = await locationsService.delete(location)
    if (result) {
      const locations = state.locations.filter(z => z.id !== location.id)
      commit('setLocations', locations)
      commit('setStatus', { deleting: false })
      return result
    } else {
      commit('setZones', [])
      commit('setStatus', { deleting: false, error: 'Error eliminando el sitio' })
      return null
    }
  }
}

const getters = {
  inMapLocations: state => {
    return state.locations.filter(item => item.inMap)
  }
}

export default {
  state,
  mutations,
  actions,
  getters,
  namespaced: true
}
