import api, { buildService } from './index'

const filterWithLocation = devices => {
  return devices?.filter(device => device.lat && device.lng) || []
}

export default {
  ...buildService('devices'),
  filterWithLocation,
  getEnabledCommands: deviceId => api.get(`devices/metadata/${deviceId}`)
}
