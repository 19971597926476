<template lang="pug">
button.commands-indicator-button(:title='title' type='button' @click='$emit("click")')
  icon(:icon='icon' :style='{ color: "white" }')
  span.pending-count(v-if='unreaded') {{ unreaded > 99 ? "+99" : unreaded }}
</template>

<script>
export default {
  props: {
    icon: { type: String },
    title: { type: String },
    unreaded: { type: Number }
  }
}
</script>

<style lang="scss" scoped>

.commands-indicator-button {
  border: none;
  height: 40px;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: none;
  color: inherit;
  position: relative;
}

.pending-count {
  position: absolute;
  right: 0;
  top: 0;
  background: red;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.8em;
}
</style>
