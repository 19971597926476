import distributorsService from '@/services/distributors.service'

const state = () => ({
  distributor: null,
  isLoading: false
})

const mutations = {
  setDistributor: (state, distributor) => {
    state.distributor = distributor
  },
  setLoading: (state, isLoading) => {
    state.isLoading = isLoading
  }
}

const actions = {
  loadGlobal: async ({ commit }, user) => {
    try {
      commit('setLoading', true)
      const result = await distributorsService.getCurrent()
      if (result) {
        commit('setDistributor', result)
      }
      commit('setLoading', false)
      return result
    } catch (err) {
      commit('setDistributor', null)
      commit('setLoading', false)
    }
  }
}

const getters = {}

export default {
  state,
  mutations,
  actions,
  getters,
  namespaced: true
}
