const options = {
  style: 'decimal',
  minimumFractionDigits: 2,
  maximumFractionDigits: 2
}
const currencyFormatter = new Intl.NumberFormat('es-CO', options)

export default function (value) {
  if (!value && value !== false && value !== 0) return '-'
  return `$ ${currencyFormatter.format(value)}`
}
