import { buildService } from './index'
import store from '../store'

const endpoints = buildService('updates')
const updateTime = 8000
const S4 = () => Math.floor(Math.random() * 0x10000 /* 65536 */).toString(16)
const GUID = () => S4() + S4() + '-' + S4() + '-' + S4() + '-' + S4() + '-' + S4() + S4() + S4()
let timeout = null
const variablesSubscriptions = {}

const getUpdates = async () => {
  if (!window.name.match(/^GUID-/)) {
    window.name = 'GUID-' + GUID()
  }
  var result = await endpoints.silence.getAll({}, { headers: { sessionCode: window.name } })
  timeout = setTimeout(getUpdates, updateTime)
  if (result) {
    for (let i = 0; i < result.devices.length; i++) {
      const device = result.devices[i]
      store.dispatch('devices/update', device)
    }
    for (let i = 0; i < result.commands.length; i++) {
      store.dispatch('commands/update', result.commands[i])
    }
    for (let i = 0; i < result.variables.length; i++) {
      const variable = result.variables[i]
      const deviceSubscriptions = variablesSubscriptions[variable.deviceId] || []
      deviceSubscriptions.forEach(callback => callback(variable))
    }
    if (result.events) {
      store.dispatch('notifications/update', result.events)
    }
    if (result && result.notificationIds && result.notificationIds.length) {
      await endpoints.silence.save(result.notificationIds, { headers: { sessionCode: window.name } })
    }
  }
}

const start = () => {
  timeout = setTimeout(getUpdates, updateTime)
}

const stop = () => {
  clearInterval(timeout)
}

const onVariablesUpdate = deviceId => ({
  subscribe: (callback) => {
    if (!variablesSubscriptions[deviceId]) variablesSubscriptions[deviceId] = []
    variablesSubscriptions[deviceId].push(callback)
    return {
      unsubscribe: () => {
        const index = variablesSubscriptions[deviceId].findIndex(cb => cb === callback)
        variablesSubscriptions[deviceId].splice(index, 1)
      }
    }
  }
})

export default {
  start,
  stop,
  onVariablesUpdate
}
