<template lang="pug">
.basic-page
  slot
</template>

<script>
export default {}
</script>

<style lang="scss" scoped>
.basic-page {
  background: #ffffff;
  display: flex;
  flex: 1;
}
</style>
