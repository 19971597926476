import { render, staticRenderFns } from "./Inpoint.vue?vue&type=template&id=6b2cf160&scoped=true&lang=pug&"
import script from "./Inpoint.vue?vue&type=script&lang=js&"
export * from "./Inpoint.vue?vue&type=script&lang=js&"
import style0 from "./Inpoint.vue?vue&type=style&index=0&id=6b2cf160&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6b2cf160",
  null
  
)

export default component.exports