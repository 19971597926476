<template lang="pug">
.devices
  b-loading(:is-full-page='true' :active.sync='loading')
  list-tools(
    @reload='getDevices'
    @check='toggleCheck'
    @create='create'
    @remove='remove'
    :allow-to-remove='!!selectedDeviceId && permissions.devicesRemove'
    :allow-to-create='permissions.devicesCreate'
  )
  .devices
    .device-group(v-for='group in groups' :key='group')
      button.device-group-name(@click='toggleGroup(group)')
        span {{ group || 'Sin agrupar' }}
        span.count {{devicesByGroup[group].length}}
      .device-list(v-if='groupsOpened[group]')
        .device(
          :key='device.id'
          v-for='device in devicesByGroup[group]'
          @click='select(device)'
          :class='{ selected: device.id === selectedDeviceId }'
        )
          .info-item.status
            device-status(:device='device')
          .info-item.name {{ device.name }}
          b-tooltip(
            v-if='allowCheck'
            label='Mostrar u ocultar del mapa'
            :delay='tooltipOptions.delay'
            :position='tooltipOptions.position.default'
            :type='tooltipOptions.type.default'
          )
            label.info-item.in-map
              input(type='checkbox' v-model='device.inMap' @dblclick='thisInMap(device)')
          device-direction.info-item(:device='device')
          b-tooltip(
            :label='getIgnitionText(device.ignition)'
            :delay='tooltipOptions.delay'
            :position='tooltipOptions.position.left'
            :type='tooltipOptions.type.default'
            :size="tooltipOptions.size.small"
            multilined
          )
            .info-item.ignition
              b-icon(v-if='device.ignition !== null' pack='fa' icon='lightbulb' :class='getIgnitionClass(device.ignition)')
          //.info-item.battery x%
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import DeviceDirection from '@/components/devices/DeviceDirection.vue'
import DeviceStatus from '@/components/devices/DeviceStatus.vue'
import { openCreateDeviceModal } from '@/components/devices/create-device.modal'
import localStorage from '@/database/local-storage'
import devicesService from '@/services/devices.service'
import { tooltipOptions } from '@/constants/configs'
import toastService from '@/services/toast.service'
import ListTools from './ListTools'

export default {
  components: { DeviceDirection, DeviceStatus, ListTools },
  props: {
    allowCheck: { type: Boolean },
    devices: { type: Array }
  },
  data () {
    return {
      groupsOpened: {},
      tooltipOptions,
      selectedId: null
    }
  },
  async created () {
    this.getDevices()
    const groupsOpened = await localStorage.getItem('devicesToggleStatus')
    if (groupsOpened) {
      this.groupsOpened = groupsOpened
    }
  },
  computed: {
    ...mapState({
      loading: state => state.devices.loading,
      error: state => state.devices.error,
      allDevices: state => state.devices.devices,
      selectedDevice: state => state.devices.selectedDevice,
      currentUser: state => state.auth.user || {}
    }),
    ...mapGetters({
      permissions: 'auth/permissions'
    }),
    groupBy () {
      return this.currentUser.isDistributorOwnerUser ? 'clientName' : 'group'
    },
    selectedDeviceId () {
      return this.selectedId || this.selectedDevice?.id
    },
    toShowDevices () {
      return this.devices || this.allDevices
    },
    devicesWithLocation () {
      return devicesService.filterWithLocation(this.toShowDevices)
    },
    groups () {
      return this.toShowDevices.reduce((groups, device) => {
        const groupName = device[this.groupBy]
        if (!groups.includes(groupName)) {
          groups.push(groupName)
        }
        return groups
      }, [])
    },
    devicesByGroup () {
      return this.toShowDevices.reduce((memory, device) => {
        const groupName = device[this.groupBy]
        if (!memory[groupName]) {
          memory[groupName] = []
        }
        memory[groupName].push(device)
        return memory
      }, {})
    }
  },
  watch: {
    error () {
      if (this.error) {
        this.$toast.error(this.error)
      }
    }
  },
  methods: {
    ...mapActions({
      getDevices: 'devices/getDevices',
      removeDevice: 'devices/remove',
      setSelectedDevice: 'devices/setSelectedDevice',
      setCenter: 'map/setCenter',
      setZoom: 'map/setZoom',
      setFocus: 'map/setFocus',
      setFollowId: 'map/setFollowId'
    }),
    toggleCheck () {
      const inMap = !this.devicesWithLocation.some(d => d.inMap)
      this.devicesWithLocation.forEach(device => {
        device.inMap = inMap
      })
    },
    thisInMap (device) {
      if (device.inMap) {
        this.devicesWithLocation.forEach(device => {
          device.inMap = false
        })
        device.inMap = true
        this.setZoom(12)
      }
    },
    select (device) {
      if (device) {
        this.selectedId = device.id
        this.setSelectedDevice(device)
        if (device.lat && device.lng) {
          if (!device.inMap) device.inMap = true
          this.setFocus({ center: [...device.latLng] })
          this.setFollowId(device.id)
        }
      } else {
        this.selectedId = null
        this.setSelectedDevice(null)
        this.setFollowId(null)
      }
    },
    async toggleGroup (groupName) {
      const newValue = !this.groupsOpened[groupName]
      this.$set(this.groupsOpened, groupName, newValue)
      await localStorage.setItem('devicesToggleStatus', this.groupsOpened)
    },
    getIgnitionClass (ignition) {
      if (ignition === null) return ''
      return `ignition-${ignition ? 'on' : 'off'}`
    },
    getIgnitionText (ignition) {
      if (ignition === null) return 'No se tiene información de ignición'
      return ignition ? 'Encendido' : 'Apagado'
    },
    create () {
      openCreateDeviceModal({
        modal: this.$buefy.modal,
        parent: this,
        props: {}
      })
    },
    async remove () {
      const device = this.toShowDevices.find(d => d.id === this.selectedDeviceId)
      if (!await toastService.confirm(`¿Desea eliminar el equipo ${device.name} permanentemente? Los datos permanecerán en nuestra base de datos por un mes.`)) return
      const result = await this.removeDevice(device)
      if (result) {
        toastService.show(`Equipo ${device.name} eliminado`)
        this.select(null)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~@/scss/variables/colors';
@import '~@/scss/variables/sizes';

.device-group-name {
  padding: $base-padding;
  background: #dfdfdf;
  border: none;
  border-bottom: solid 1px #bcbcbc;
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 30px;
  width: 100%;

  @media only screen and (max-width: 900px) {
    min-height: 50px;
  }

  .count {
    font-size: 0.8em;
  }
}

.device {
  display: grid;
  grid-template-columns: 30px 1fr repeat(4, 30px);
  min-height: 30px;
  cursor: pointer;

  @media only screen and (max-width: 900px) {
    min-height: 50px;
  }

  &.selected {
    border: 1px solid $main-color;
    background-color: $white;
  }

  &:hover {
    background: $white;
  }
}

.ignition-on {
  color: $yellow;
}

.ignition-off {
  color: $black;
}

.hand-stop {
  color: $bad-red;
}

.info-item {
  height: 100%;
  display: flex;
  align-items: center;
}

.selected .info-item.name {
  font-weight: 700;
}

.in-map,
.status {
  justify-content: center;
}
</style>
