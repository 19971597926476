import reportsService from '@/services/reports.service'
import { mapPolylineColors } from '@/constants/configs'
import icoInfo from '@/assets/icons/info.svg'
import icoStop from '@/assets/icons/cancel.svg'

const state = () => ({
  reports: [],
  isLoading: false,
  isError: false,
  colors: [...mapPolylineColors],
  currentColor: '',
  focusedReport: null
})

const mutations = {
  addReport: (state, report) => {
    state.reports = [report, ...state.reports]
  },
  editReport: (state, { reportId, editedReport }) => {
    state.reports = state.reports.map(report => {
      return report.id === reportId ? editedReport : report
    })
  },
  setReports: (state, reports) => {
    state.reports = reports
  },
  setColors: (state, colors) => {
    state.colors = colors
  },
  setCurrentColor: (state) => {
    const [color, ...rest] = state.colors
    state.currentColor = color
    state.colors = rest.length ? rest : [...mapPolylineColors]
  },
  setStatus: (state, { isLoading, isError = false }) => {
    state.isLoading = isLoading
    state.isError = isError
  },
  setFocusedReport: (state, report) => {
    state.focusedReport = report
  }
}

const actions = {
  deleteReport: async ({ dispatch, commit, state }, id) => {
    if (state.focusedReport && state.focusedReport.id === id) {
      await dispatch('map/removeMarkerFocus', null, { root: true })
      await commit('setFocusedReport', null)
    }
    const newReports = state.reports.filter(report => report.id !== id)
    commit('setReports', newReports)
  },
  getReport: async ({ commit, state }, { startDate, endDate, devicesId }) => {
    commit('setStatus', { isLoading: true })
    const result = await reportsService.locationsSummary({ startDate, endDate, devicesId })
    return new Promise((resolve, reject) => {
      if (result && result.length) {
        commit('setStatus', { isLoading: false })
        const report = result[0]
        if (report.locations.length) {
          report.id = `${new Date().getTime()}`
          report.startDate = startDate
          report.endDate = endDate
          report.latlngs = report.locations.map(location => [location.lat, location.lng])
          report.selected = true
          report.showDetails = false
          commit('setCurrentColor')
          report.color = state.currentColor
          commit('addReport', report)
          resolve({ reportWithData: true })
        } else {
          resolve({ reportWithData: false })
        }
      } else {
        commit('setStatus', { isLoading: false, isError: true })
        reject(new Error('Error'))
      }
    })
  },
  setSelectReport: ({ commit, state }, { reportId, selected }) => {
    const report = state.reports.find(report => report.id === reportId)
    const editedReport = { ...report, selected }
    commit('editReport', { reportId, editedReport })
  },
  setShowDetails: ({ commit, state }, { reportId, showDetails }) => {
    const report = state.reports.find(report => report.id === reportId)
    const editedReport = { ...report, showDetails }
    commit('editReport', { reportId, editedReport })
  },
  goToLocation: async ({ dispatch, commit }, { location, report }) => {
    const markerFocus = {
      center: [location.lat, location.lng],
      icon: location.isStopped ? icoStop : icoInfo,
      location
    }
    await dispatch('map/setMarkerFocus', markerFocus, { root: true })
    commit('setFocusedReport', report)
  }
}

const getters = {
  selectedReports: state => state.reports.filter(report => report.selected)
}

export default {
  state,
  mutations,
  actions,
  getters,
  namespaced: true
}
