let componentInstance = null

export const openCreateDeviceModal = async ({ props, parent, modal }) => {
  if (!componentInstance) {
    const component = await import(/* webpackChunkName: "device-form-modal" */ './DeviceFormModal.vue')
    componentInstance = component.default
  }

  modal.open({
    component: componentInstance,
    parent,
    hasModalCard: true,
    trapFocus: true,
    canCancel: ['escape', 'x'],
    props
  })
}
