import zonesService from '@/services/zones.service'

const state = () => ({
  loading: false,
  deleting: false,
  error: null,
  zones: []
})

const normalizeZone = item => {
  item.inMap = true
  return item
}

const mutations = {
  setZones: (state, zones) => {
    state.zones = zones
  },
  setStatus: (state, { loading, deleting, error }) => {
    state.loading = loading
    state.deleting = deleting
    state.error = error
  }
}

const actions = {
  getZones: async ({ commit }, params) => {
    commit('setStatus', { loading: true })
    const result = await zonesService.getAll(params)
    if (result) {
      const zones = result
      zones.forEach(device => normalizeZone(device))
      commit('setZones', zones)
      commit('setStatus', { loading: false })
    } else {
      commit('setZones', [])
      commit('setStatus', { loading: false, error: 'Error cargando zonas TODO' })
    }
  },
  saveZone: async ({ commit, state }, zone) => {
    const result = await zonesService.save(zone)
    if (result) {
      if (zone.id) {
        const editedZone = state.zones.find(z => z.id === zone.id)
        Object.assign(editedZone, zone)
        commit('setZones', [...state.zones])
      } else {
        const zones = [...state.zones, normalizeZone(result)]
        commit('setZones', zones)
      }
      return result
    } else {
      return result
    }
  },
  removeZone: async ({ commit, state }, zone) => {
    commit('setStatus', { deleting: true })
    const result = await zonesService.delete(zone)
    if (result) {
      const zones = state.zones.filter(z => z.id !== zone.id)
      commit('setZones', zones)
      commit('setStatus', { deleting: false })
      return result
    } else {
      commit('setStatus', { deleting: false, error: 'Error eliminando la zona' })
      return result
    }
  }
}

const getters = {
  inMapZones: state => {
    return state.zones.filter(zone => zone.inMap)
  }
}

export default {
  state,
  mutations,
  actions,
  getters,
  namespaced: true
}
