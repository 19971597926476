import { format } from 'date-fns'
import { es } from 'date-fns/locale'
import { defaultDateTimeFormat } from '@/constants/formats'

export default function (value) {
  if (!value) return ''
  if (typeof value === 'string') {
    return format(new Date(value), defaultDateTimeFormat, { locale: es })
  } else if (value instanceof Date) {
    return format(value, defaultDateTimeFormat)
  }
  return 'Invalid Date Format'
}
