import { format } from 'date-fns'
import api from './index'

export default {
  inOutZones: params => api.post('reports/in-out-zones', params),
  speed: params => api.post('reports/speed', params),
  byTypes: params => api.post('reports/by-types', params),
  locationsSummary: params => api.post('reports/locations-summary', params),
  getStartEndDateTimes: () => {
    return {
      startDate: format(new Date(), 'yyyy-MM-dd') + 'T00:00',
      endDate: format(new Date(), 'yyyy-MM-dd') + 'T' + format(new Date(), 'HH:mm')
    }
  },
  getStartEndDates: () => {
    return {
      startDate: format(new Date(), 'yyyy-MM-dd'),
      endDate: format(new Date(), 'yyyy-MM-dd')
    }
  }
}
