import L from 'leaflet'
import devicesService from '../../services/devices.service'
import { commandTypeDescriptions } from '../../constants/enums'
import { getColoredArrowIcon, markerIcons } from '@/views/devices/constants'

const state = () => ({
  loading: false,
  savingLoading: false,
  error: null,
  devices: [],
  selectedDevice: null,
  selectedDeviceDetails: null,
  latestPoints: [],
  detailsLoading: false
})

const normalizeDevice = device => {
  device.inMap = device.lat && device.lng
  device.latLng = [device.lat, device.lng]
  const rotationAngle = device.acimut || 0
  if (device.image && markerIcons[device.image]) {
    const icon = markerIcons[device.image]
    if (icon.colorIcon) {
      device.LIcon = L.divIcon({
        className: 'custom-marker',
        html: getColoredArrowIcon(icon.colorIcon, rotationAngle),
        iconSize: icon.iconSize,
        iconAnchor: icon.iconAnchor
      })
    } else {
      const style = [
        `transform: rotate(${rotationAngle}deg)`,
        `width: ${icon.iconSize[0]}px;`,
        `height: ${icon.iconSize[1]}px;`,
        `background-image: url('${icon.url}')`,
        'background-position: center',
        'background-repeat: no-repeat',
        `background-size: ${icon.iconSize[0]}px ${icon.iconSize[1]}px`
      ]
      device.LIcon = L.divIcon({
        className: 'custom-marker',
        html:
          `<div
            class="rotated-icon"
            style="${style.join(';')}"
          ></div>`,
        iconSize: icon.iconSize,
        iconAnchor: icon.iconAnchor
      })
    }
  }

  return device
}

const mutations = {
  setDevices: (state, devices) => {
    state.devices = devices
  },
  setDevice: (state, device) => {
    const currentDevice = state.devices.find(d => d.id === device.id)
    if (currentDevice) {
      Object.assign(currentDevice, device)
      normalizeDevice(currentDevice)
    }
    if (state.selectedDevice && state.selectedDevice.id === device.id) {
      state.selectedDevice = currentDevice
    }
  },
  setStatus: (state, { loading, error }) => {
    state.loading = loading
    state.error = error
  },
  setSavingStatus: (state, { loading, error }) => {
    state.savingLoading = loading
    state.error = error
  },
  setSelectedDevice: (state, device) => {
    state.selectedDevice = device
    if (!device) state.latestPoints = []
  },
  setSelectedDeviceDetails: (state, deviceDetails) => {
    state.selectedDeviceDetails = deviceDetails
  },
  setDetailsLoading: (state, loading) => {
    state.detailsLoading = loading
  },
  setLatestPoints: (state, latestPoints) => {
    state.latestPoints = latestPoints
  }
}

const actions = {
  getDevices: async ({ commit }, params) => {
    commit('setStatus', { loading: true })
    const newParams = {
      ignoreDisabled: true,
      ...params
    }
    const result = await devicesService.getAll(newParams)
    if (result) {
      const devices = result
      devices.forEach(device => normalizeDevice(device))
      commit('setDevices', devices)
      commit('setStatus', { loading: false })
    } else {
      commit('setDevices', [])
      commit('setStatus', { loading: false, error: 'Error cargando equipos TODO' })
    }
  },
  setSelectedDevice: async ({ commit, state }, device) => {
    const changed = !state.selectedDevice || state.selectedDevice.id !== device?.id
    commit('setSelectedDevice', device ? { ...device } : null)
    if (changed) {
      if (device) {
        commit('setDetailsLoading', true)
        const {
          deviceEnabledCommands,
          nearbyZones,
          nearbyLocations,
          address,
          latestPoints
        } = await devicesService.getEnabledCommands(device.id)
        const enabledCommands = (deviceEnabledCommands || []).map(itemCommand => ({ id: itemCommand, command: commandTypeDescriptions[itemCommand] }))
        const details = { enabledCommands: enabledCommands || [], nearbyZones, nearbyLocations, address }
        await Promise.all([
          commit('setSelectedDeviceDetails', details),
          commit('setLatestPoints', latestPoints),
          commit('setDetailsLoading', false)
        ])
      } else {
        await commit('setSelectedDeviceDetails', null)
        await commit('setLatestPoints', [])
      }
    }
  },
  update: async ({ rootState, commit, dispatch, state }, device) => {
    const selectedDevice = rootState.devices.selectedDevice
    let needToCenter = false
    if (selectedDevice && selectedDevice.id === device.id && device.id === rootState.map.followId && device.lat && device.lng) {
      needToCenter = true
    }
    if (state.selectedDevice && state.selectedDevice.id === device.id) {
      // adding the this location to the latestPoint array if neccesary
      const recent = state.latestPoints[0]
      if (recent && (recent[0] !== device.lat || recent[1] !== device.lng)) {
        const latestPoints = [[device.lat, device.lng], ...state.latestPoints]
        await commit('setLatestPoints', latestPoints)
      }
    }
    await commit('setDevice', device)
    if (needToCenter) {
      await dispatch('map/setCenter', [device.lat, device.lng], { root: true })
    }
  },
  save: async ({ commit, dispatch }, device) => {
    try {
      const { isNew, ...rest } = device
      commit('setSavingStatus', { loading: true })
      let result = null
      if (isNew) {
        result = await devicesService.create(rest)
      } else {
        result = await devicesService.update(rest)
      }
      if (result) {
        await dispatch('getDevices')
      }
      commit('setSavingStatus', { loading: false })
      return result
    } catch (error) {
      commit('setSavingStatus', { loading: false, error })
    }
  },
  remove: async ({ commit, state }, device) => {
    try {
      await commit('setSavingStatus', { loading: true })
      let result = await devicesService.delete(device)
      if (result) {
        if (state.selectedDevice && state.selectedDevice.id === device.id) {
          await commit('setSelectedDevice', null)
          await commit('setSelectedDeviceDetails', null)
        }
        const devices = state.devices.filter(d => d.id !== device.id)
        await commit('setDevices', devices)
      }
      await commit('setSavingStatus', { loading: false })
      return result
    } catch (error) {
      await commit('setSavingStatus', { loading: false, error })
    }
  }
}

const getters = {
  getDeviceById: state => id => {
    return state.devices.find(device => device.id === id)
  }
}

export default {
  state,
  mutations,
  actions,
  getters,
  namespaced: true
}
