import { DialogProgrammatic as Dialog, ToastProgrammatic as Toast } from 'buefy'

export default {
  show (message, duration = 3500) {
    Toast.open({ message, duration })
  },
  error (message, duration = 3500) {
    Toast.open({
      message: message || `Ha ocurrido un error.`,
      type: 'is-danger',
      duration
    })
  },
  confirm (message, onConfirm) {
    if (onConfirm) {
      Dialog.confirm({
        confirmText: 'Sí',
        cancelText: 'Cancelar',
        message,
        onConfirm
      })
    } else {
      return new Promise(resolve => {
        Dialog.confirm({
          confirmText: 'Sí',
          cancelText: 'Cancelar',
          message,
          onConfirm: () => resolve(true),
          onCancel: () => resolve(false)
        })
      })
    }
  },
  confirmWarning (message, confirmText, onConfirm) {
    if (onConfirm) {
      Dialog.confirm({
        title: 'Atención',
        message,
        type: 'is-danger',
        confirmText,
        onConfirm
      })
    } else {
      return new Promise(resolve => {
        Dialog.confirm({
          title: 'Atención',
          message,
          type: 'is-danger',
          confirmText,
          onConfirm: () => resolve(true),
          onCancel: () => resolve(false)
        })
      })
    }
  },
  prompt (message, placeholder, maxlength, onConfirm, required = false, value = '') {
    const fullMessage = typeof message === 'string' ? message : message.map(m => '<p>' + m + '</p>').join(' ')
    Dialog.prompt({
      message: fullMessage,
      inputAttrs: {
        placeholder,
        maxlength,
        required,
        value
      },
      onConfirm,
      confirmText: 'Aceptar',
      cancelText: 'Cancelar'
    })
  }
}
