<template lang="pug">
b-modal(
  v-model='isModalActive'
  has-modal-card
  trap-focus
  aria-role="dialog"
  aria-modal
)
  modal-card(:title='title')
    slot
  //- .modal-card(style='width: auto')
  //-   header.modal-card-head
  //-     p.modal-card-title Login
  //-   section.modal-card-body
  //-     | asd asdas
  //-   footer.modal-card-foot
  //-     button.button(type='button' @click='$parent.close()') Close
  //-     button.button(is-primary) Login
</template>

<script>
export default {
  props: {
    value: { type: Boolean, default: false },
    title: { type: String, default: '' }
  },
  data () {
    return {
      isModalActive: this.value
    }
  },
  watch: {
    value () {
      this.isModalActive = this.value
    },
    isModalActive () {
      this.$emit('input', this.isModalActive)
    }
  }
}
</script>
