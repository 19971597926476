<template lang="pug">
b-field(:label='labelRaw || $t(label)' horizontal)
  button.shipping-file.bg-primary(v-for='file in savedFiles' @click='download(file)' type='button')
    | {{ file | fileName }}
    a.remove-file(@click.stop='removeFile(file)' v-if='!readonly') x
  .shipping-file.pending-file(v-for='file in forSaveFiles')
    | {{ file.name }}
    a.remove-file(@click='removeTemp(file)') x
  .shipping-file.bg-primary(v-if='saving') Uploading...
  input.form-control(type='file' v-if='allowMoreFiles && !readonly' @input='inputFileChanged' :required='inputRequired')
</template>

<script>
import filesService from '@/services/files.service'

export default {
  props: {
    label: { type: String },
    labelRaw: { type: String },
    multiple: { type: Boolean },
    readonly: { type: Boolean },
    required: { type: Boolean },
    value: { type: String },
    folderName: { type: String }
  },
  data () {
    return {
      savedFiles: [],
      forSaveFiles: [],
      saving: false
    }
  },
  watch: {
    value: {
      immediate: true,
      handler () {
        this.savedFiles = this.value ? this.value.split('|') : []
      }
    }
  },
  computed: {
    allowMoreFiles () {
      return this.multiple || (this.savedFiles.length + this.forSaveFiles.length) === 0
    },
    inputRequired () {
      return this.required && (!this.multiple || this.savedFiles.length + this.forSaveFiles.length === 0)
    }
  },
  methods: {
    async inputFileChanged (event) {
      if (event.target.files.length) {
        event.target.files.forEach(file => this.forSaveFiles.push(file))
      }
      event.target.value = null
    },
    removeTemp (file) {
      this.forSaveFiles = this.forSaveFiles.filter(f => f.name !== file.name)
    },
    removeFile (file) {
      if (confirm('¿Remove file?')) {
        this.savedFiles = this.savedFiles.filter(f => f !== file)
      }
    },
    async upload () {
      if (this.forSaveFiles.length) {
        this.saving = true
        const filesResult = await Promise.all(this.forSaveFiles.map(file => filesService.upload(file, this.folderName)))
        this.savedFiles.push(...filesResult.filter(result => result).map(result => result.dbPath))
        this.forSaveFiles = this.forSaveFiles.filter((_, index) => !filesResult[index])
        this.saving = false
      }
      const value = this.savedFiles.join('|')
      return value
    },
    async download (file) {
      await filesService.download(file)
    }
  }
}
</script>

<style lang="scss" scoped>
.shipping-file {
  padding: 5px 15px;
  margin-bottom: 3px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  width: 100%;
  border: none;
}

.remove-file {
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  width: 25px;
  background: rgba(0,0,0, 0.2);
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  transition: background 0.3s ease-in-out;

  &:hover {
    background: rgba(0,0,0, 0.5);
  }
}

.pending-file {
  border: dashed 1px #8a8a8a;
  background: #f5f5f5;
}
</style>
