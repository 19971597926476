<template lang="pug">
nav.app-navbar(v-show='isNavBarVisible' id="navbar-main")
  .navbar-start
    button.toggle-menu-button(@click='toggleMenu')
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="icon feather feather-menu"><line x1="3" y1="12" x2="21" y2="12"></line><line x1="3" y1="6" x2="21" y2="6"></line><line x1="3" y1="18" x2="21" y2="18"></line></svg>
    logo.logo
    commands-indicator.ml-5.navbar-element.no-padding
    //- disconnected-devices.ml-1.navbar-element.no-padding
    notifications.ml-1.navbar-element.no-padding
  .navbar-end
    a.navbar-element.logout-button(
      title='Cerrar sesión'
      @click='tryLogout'
    )
      icon.icon(icon='sign-out-alt' :style='{ color: "white" }' )
      span.text Cerrar sesión
</template>

<script>
import { mapActions } from 'vuex'
import Logo from '@/components/shared/Logo'
import CommandsIndicator from '@/components/commands/CommandsIndicator'
import DisconnectedDevices from '@/components/devices/DisconnectedDevices'
import Notifications from '@/components/devices/Notifications'
import MenuItem from './MenuItem'
import menu from '../constants/menu'
import { navbarStatusChanged } from '@/utils/events'

export default {
  components: { Logo, MenuItem, CommandsIndicator, DisconnectedDevices, Notifications },
  data () {
    return { menu, isNavBarVisible: true }
  },
  methods: {
    ...mapActions({
      logout: 'auth/logout'
    }),
    tryLogout () {
      this.logout()
    },
    toggleMenu () {
      navbarStatusChanged.dispatch()
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~@/scss/variables/colors';
@import '~@/scss/variables/sizes';

.app-navbar {
  color: white;
  height: var(--app-navbar);
  display: flex;
  justify-content: space-between;
}

.logo {
  margin-left: 10px;

  @media only screen and (min-width: 900px) {
    margin-left: 20px;
  }
}

.toggle-menu-button {
  border: none;
  background: none;
  cursor: pointer;

  @media only screen and (min-width: 900px) {
    display: none;
  }

  .icon {
    stroke: white;
  }
}

.navbar-start,
.navbar-end {
  display: flex;
  align-items: center;
}

.navbar-element {
  display: flex;
  align-items: center;
  height: 40px;
  color: inherit;
  padding: 15px;

  &.no-padding {
    padding: 0;
  }

  .icon+.text {
    margin-left: 10px;
  }

  .text {
    color: inherit;
  }

  &:hover {
    background: rgba(255,255,255,0.2);
    border-radius: 3px;
  }
}

.logout-button {
  @media only screen and (max-width: 900px) {
    .text {
      display: none;
    }
  }
}

</style>

<style lang="scss">
.navbar-container {
  display: flex;
  flex-direction: column;
}

</style>
