import store from '../store'
// import { userRoleEnum } from "@/constants";
import { userPermissions } from '@/constants/enums'

export default {
  computed: {
    userPermissions () {
      return userPermissions
    },
    currentUser () {
      return this.$store.state.auth.user || {}
    },
    currentDistributor () {
      return this.$store.getters['auth/distributor']
    },
    currentClient () {
      return this.$store.getters['auth/client']
    }
    // isSystem() {
    //   return this.currentUser.roleId === userRoleEnum.System
    // },
    // isAdmin() {
    //   return this.currentUser.roleId === userRoleEnum.Admin
    // },
    // isAuthorizer() {
    //   return this.currentUser.roleId === userRoleEnum.Authorizer
    // },
    // isSupplier() {
    //   return this.currentUser.roleId === userRoleEnum.Supplier
    // },
  },
  methods: {
    can (permissionId) {
      return userCan(permissionId)
    }
  }
}

export const userCan = permissionId => {
  const currentUser = store.state.auth.user || {}
  const permissions = currentUser && currentUser.permissionsArray
  if (permissions) {
    return permissions.includes(permissionId)
  }
  return false
}
