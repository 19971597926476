<template lang="pug">
b-field(:label='label' label-position='on-border')
  b-input(
    v-model='rawValue'
    :icon='icon'
    :type='type'
    :placeholder='placeholder'
    :required='required'
    :readonly='readonly'
    :disabled='disabled'
    :maxlength='maxlength'
    :min='min'
    validation-message="Este campo es requerido"
    @input='valueChanged'
  )
</template>

<script>
export default {
  props: {
    label: { type: String },
    icon: { type: String },
    type: { type: String, default: 'text' },
    required: { type: Boolean },
    disabled: { type: Boolean },
    readonly: { type: Boolean },
    placeholder: { type: String },
    maxlength: { type: Number },
    min: { type: [Number, String] },
    value: {}
  },
  data () {
    return {
      rawValue: this.value
    }
  },
  watch: {
    value () {
      this.rawValue = this.value
    }
  },
  methods: {
    valueChanged () {
      this.$emit('input', this.rawValue)
    }
  }
}
</script>
