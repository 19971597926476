<template lang="pug">
b-field(:label='label' label-position='on-border')
  b-colorpicker(
    v-model='rawValue'
    :alpha='alpha'
    :required='required'
    :readonly='readonly'
    :disabled='disabled'
    :color-formatter='formatter'
    :color-parser='parser'
    validation-message="Este campo es requerido"
    @input='valueChanged'
    :placeholder='placeholder'
  )
</template>

<script>
import Color from 'buefy/src/utils/color'

export default {
  props: {
    label: { type: String },
    required: { type: Boolean },
    disabled: { type: Boolean },
    alpha: { type: Boolean, default: true },
    readonly: { type: Boolean },
    placeholder: { type: String },
    value: { type: String }
  },
  data () {
    return {
      rawValue: this.parser(this.value)
    }
  },
  watch: {
    value () {
      this.rawValue = this.parser(this.value)
    }
  },
  methods: {
    valueChanged (event) {
      this.$emit('input', event.toString('hexa'))
    },
    formatter (color) {
      return color.toString('hexa')
    },
    parser (color) {
      return Color.parse(color || '#00000000')
    }
  }
}
</script>
