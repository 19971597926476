export const deviceStatusEnum = {
  Unknown: 0,
  Online: 1,
  Inactive: 2,
  Sleeping: 3
}

export const shipmentStatusEnum = {
  Pending: 1,
  Assigned: 3,
  Transit: 5,
  Delivered: 10
}

export const shipmentStatusDescriptions = {
  [shipmentStatusEnum.Pending]: 'Pendiente',
  [shipmentStatusEnum.Assigned]: 'Asignado',
  [shipmentStatusEnum.Transit]: 'En Tránsito',
  [shipmentStatusEnum.Delivered]: 'Entregado'
}

export const shipmentStatusColors = {
  [shipmentStatusEnum.Transit]: 'blue',
  [shipmentStatusEnum.Delivered]: 'green'
}

export const deviceStatusColors = {
  [deviceStatusEnum.Unknown]: '#b5b5b5',
  [deviceStatusEnum.Online]: '#06c337',
  [deviceStatusEnum.Inactive]: '#ff4e4e',
  [deviceStatusEnum.Sleeping]: '#3e4dfd'
}

export const deviceStatusDescriptions = {
  [deviceStatusEnum.Unknown]: 'Desconocido',
  [deviceStatusEnum.Online]: 'En línea',
  [deviceStatusEnum.Inactive]: 'Inactivo',
  [deviceStatusEnum.Sleeping]: 'Dormido/detenido'
}

export const commandTypeEnum = {
  TurnOffEngine: 0,
  TurnOnEngine: 1,
  OpenDoors: 2,
  CloseDoors: 3,
  OpenOutput: 4,
  CloseOutput: 5,
  CobanLOAD: 101,
  CobanON: 102,
  CobanHelpMe: 103,
  LoginConfirm: 104,
  HeartbeatConfirm: 105
}

export const userPermissions = {
  GET_LOGS: 1,
  GET_EWS: 5,
  GET_CONTRACTS: 10,
  GET_CONTRACTS_INVOICES: 15,
  GET_DEVICE_REPORTS: 20,
  INPOINT_APP: 25,
  GET_SHIPMENTS: 30,
  CLIENTS_GET: 40,
  DEVICES_CREATE: 50,
  DEVICES_EDIT_SENSITIVE: 51
}

export const commandTypeVerb = {
  [commandTypeEnum.TurnOffEngine]: 'Apagando motor',
  [commandTypeEnum.TurnOnEngine]: 'Permitiendo encender motor',
  [commandTypeEnum.OpenDoors]: 'Abriendo puertas',
  [commandTypeEnum.CloseDoors]: 'Cerrando puertas',
  [commandTypeEnum.CobanLOAD]: 'Registrando',
  [commandTypeEnum.CobanON]: 'Registrando',
  [commandTypeEnum.CobanHelpMe]: 'Confirmando SOS',
  [commandTypeEnum.LoginConfirm]: 'Confirmando inicio de sesión'
}

export const commandTypeDescriptions = {
  [commandTypeEnum.TurnOffEngine]: 'Apagar',
  [commandTypeEnum.TurnOnEngine]: 'Encender',
  [commandTypeEnum.OpenDoors]: 'Abrir Puertas',
  [commandTypeEnum.CloseDoors]: 'Cerrar Puertas',
  [commandTypeEnum.OpenOutput]: 'Abrir Salida',
  [commandTypeEnum.CloseOutput]: 'Cerrar Salida',
  [commandTypeEnum.CobanLOAD]: 'Coban LOAD',
  [commandTypeEnum.CobanON]: 'Coban ON',
  [commandTypeEnum.CobanHelpMe]: 'Coban Help Me',
  [commandTypeEnum.LoginConfirm]: 'Login Confirm',
  [commandTypeEnum.HeartbeatConfirm]: 'Heartbeat Confirm'
}

export const commandStatusEnum = {
  /// <summary>
  /// Ya hay un comando para el mismo equipo. Esto no es permitido
  /// </summary>
  PENDING: 0,
  /// <summary>
  /// Indica que ya hay otro comando para el mismo equipo
  /// </summary>
  OTHER_QUEUE: 1,
  /// <summary>
  /// Encolado, quiere decir que el mensaje está pendiente para ser enviado cuando el equipo reporte
  /// </summary>
  QUEUED: 2,
  /// <summary>
  /// El cliente no existe. (No ha reportado desde que se inicio el servicio)
  /// </summary>
  CLIENT_NO_EXISTS: 3,
  /// <summary>
  /// Mensaje fallido por razones del servidor.
  /// </summary>
  FAILED: 4,
  /// <summary>
  /// Indica que el mensaje fue enviado al equipo. Se espera respuesta
  /// </summary>
  DISPATCHED: 5,
  /// <summary>
  /// Indica que el comando no ha sido encontrado en COMMANDS.XML
  /// </summary>
  CMD_NOT_FOUND: 6,
  /// <summary>
  /// Respuesta satisfactoria
  /// </summary>
  OK: 7,
  /// <summary>
  /// Limite de reintentos cumplidos
  /// </summary>
  TIMEOUT: 8,
  /// <summary>
  /// Se canceló el comando
  /// </summary>
  CANCELED: 9,
  /// <summary>
  /// Se solicitó cancelar el comando
  /// </summary>
  CANCEL: 10
}

export const commandStatusIcon = {
  [commandStatusEnum.PENDING]: 'spinner',
  [commandStatusEnum.OTHER_QUEUE]: 'times',
  [commandStatusEnum.QUEUED]: 'spinner',
  [commandStatusEnum.CLIENT_NO_EXISTS]: 'spinner',
  [commandStatusEnum.FAILED]: 'times',
  [commandStatusEnum.DISPATCHED]: 'spinner',
  [commandStatusEnum.CMD_NOT_FOUND]: 'times',
  [commandStatusEnum.OK]: 'check',
  [commandStatusEnum.TIMEOUT]: 'times',
  [commandStatusEnum.CANCELED]: 'times',
  [commandStatusEnum.CANCEL]: 'spinner'
}

export const commandStatusVerb = {
  [commandStatusEnum.PENDING]: 'Esperando conexión para enviar...',
  [commandStatusEnum.OTHER_QUEUE]: 'Cancelado por otro comando en cola',
  [commandStatusEnum.QUEUED]: 'Encolado para enviar...',
  [commandStatusEnum.CLIENT_NO_EXISTS]: 'Esperando conexión para enviar',
  [commandStatusEnum.FAILED]: 'Envío de comando fallido',
  [commandStatusEnum.DISPATCHED]: 'Despachado...',
  [commandStatusEnum.CMD_NOT_FOUND]: 'Comando no encontrado',
  [commandStatusEnum.OK]: 'Confirmado',
  [commandStatusEnum.TIMEOUT]: 'Tiempo de espera agotado',
  [commandStatusEnum.CANCELED]: 'Cancelado',
  [commandStatusEnum.CANCEL]: 'Intentando cancelar el comando...'
}

export const chargeFrequencyTypeEnum = {
  Daily: 1,
  Montly: 2
}

export const chargeFrequencyTypeDescriptions = {
  [chargeFrequencyTypeEnum.Daily]: 'Días',
  [chargeFrequencyTypeEnum.Montly]: 'Meses'
}

export const contractTypeEnum = {
  Anticipated: 1,
  DailyConsumption: 2
}

export const contractTypeDescriptions = {
  [contractTypeEnum.Anticipated]: 'Anticipado',
  [contractTypeEnum.DailyConsumption]: 'Consumo diario'
}

export const invoiceStatusEnum = {
  Created: 1,
  Sended: 2,
  Payed: 3,
  Cancelled: 4
}

export const invoiceStatusDescriptions = {
  [invoiceStatusEnum.Created]: 'Creada',
  [invoiceStatusEnum.Sended]: 'Enviada',
  [invoiceStatusEnum.Payed]: 'Pagada',
  [invoiceStatusEnum.Cancelled]: 'Anulada'
}

export const unitOfMeasureEnum = {
  Hour: 1,
  Day: 2,
  Week: 3,
  Month: 4,
  Year: 5
}

export const unitOfMeasureDescriptions = {
  [unitOfMeasureEnum.Hour]: 'Horas',
  [unitOfMeasureEnum.Day]: 'Días',
  [unitOfMeasureEnum.Week]: 'Semanas',
  [unitOfMeasureEnum.Month]: 'Meses',
  [unitOfMeasureEnum.Year]: 'Años'
}

export const variableTypeEnum = {
  DI: 1,
  DO: 2,
  AI: 3,
  Variable: 4
}

export const devicePorpuseEnum = {
  Tracking: 1,
  EWS: 2
}

export const ruleTypeEnum = {
  Zone: 0,
  Odometer: 1,
  Time: 2,
  Usage: 3,
  Events: 4
}

export const ruleTypeDescriptions = {
  [ruleTypeEnum.Zone]: 'Zona',
  [ruleTypeEnum.Odometer]: 'Odometro',
  [ruleTypeEnum.Time]: 'Tiempo',
  [ruleTypeEnum.Usage]: 'Uso del equipo',
  [ruleTypeEnum.Events]: 'Eventos'
}

export const ruleOdometerTypeEnum = {
  OneTime: 0,
  Repetitive: 1
}

export const ruleTimeFrequencyTypeEnum = {
  OneTime: 1,
  Hour: 2,
  Day: 3,
  Month: 4
}

export const ruleTimeFrequencyTypeDescriptions = {
  [ruleTimeFrequencyTypeEnum.OneTime]: 'Una vez',
  [ruleTimeFrequencyTypeEnum.Hour]: 'Hora(s)',
  [ruleTimeFrequencyTypeEnum.Day]: 'Día(s)',
  [ruleTimeFrequencyTypeEnum.Month]: 'Mes(es)'
}

export const devicePorpuseDescriptions = {
  [devicePorpuseEnum.Tracking]: 'Rastreo',
  [devicePorpuseEnum.EWS]: 'Estación de alerta temprana'
}

export const securityEventSeverity = {
  None: 1,
  Low: 2,
  Med: 3,
  High: 4
}

export const securityEventSeverityColors = {
  [securityEventSeverity.None]: 'green',
  [securityEventSeverity.Low]: 'blue',
  [securityEventSeverity.Med]: 'orange',
  [securityEventSeverity.High]: 'red'
}

export const securityEventSeverityDescriptions = {
  [securityEventSeverity.None]: 'Ninguna',
  [securityEventSeverity.Low]: 'Baja',
  [securityEventSeverity.Med]: 'Media',
  [securityEventSeverity.High]: 'Alta'
}

export const fileFolders = {
  Inpoint: 'inpoint'
}

export const acimuitDirections = {
  N: 'Norte',
  NE: 'Noreste',
  E: 'Este',
  SE: 'Sureste',
  S: 'Sur',
  SO: 'Suroeste',
  O: 'Oeste',
  NO: 'Noroeste',
  'WITHOUT_INFORMATION': 'No se tiene información de acimut'
}
