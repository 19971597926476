<template lang="pug">
form.form-mid(@submit.prevent='submit' :autocomplete='autocomplete' :form-horizontal='horizontal')
  slot
  .buttons
    b-button(type='is-primary' native-type='submit' :loading='isSaving') {{ buttonText }}
</template>

<script>
export default {
  props: {
    horizontal: { type: Boolean, default: true },
    isSaving: { type: Boolean },
    buttonText: { type: String, default: 'Guardar' },
    autocomplete: { type: String, default: 'on' }
  },
  methods: {
    submit ($event) {
      this.$emit('submit', $event)
    }
  }
}
</script>

<style lang="scss" scoped>
.buttons {
  display: flex;
  justify-content: flex-end;
}
</style>
