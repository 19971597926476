<template lang="pug">
b-collapse.card(animation='slide')
  template(#trigger='props')
    .card-header(role="button")
      p.card-header-title {{ title }}
      a.card-header-icon
        icon(:icon="props.open ? 'chevron-down' : 'chevron-up'")
  .card-content
    slot
</template>

<script>
export default {
  props: {
    title: { type: String }
  }
}
</script>
