<template lang="pug">
.select
  select.input(:id='id' @input='emitValues' v-model='rawValue' :required='required' :disabled='disabled')
    option(value) {{ isLoading ? 'Cargando...' : 'Seleccione uno' }}
    option(v-for='item in finalItems' :key='item.id' :value='item.id') {{ item.description }}
</template>

<script>
export default {
  props: {
    required: { type: Boolean },
    disabled: { type: Boolean },
    isLoading: { type: Boolean },
    id: { type: String },
    enum: { type: Object },
    enumNames: { type: Object },

    value: { type: String, default: '' },
    items: { type: Array },
    valueName: { type: String, default: 'id' },
    showName: { type: String, default: 'name' }
  },
  data () {
    return {
      rawValue: null
    }
  },
  created () {
    this.rawValue = this.value
  },
  computed: {
    finalItems () {
      if (this.enum && this.enumNames) {
        return Object.values(this.enum).map(id => ({ id, description: this.enumNames[id] }))
      }
      if (this.items) {
        return this.items.map(item => ({ id: item[this.valueName], description: item[this.showName] }))
      }
      return []
    }
  },
  watch: {
    value () {
      this.rawValue = this.value
    }
  },
  methods: {
    emitValues ($event) {
      this.$emit('input', $event.target.value === '' ? null : $event.target.value)
    }
  }
}
</script>
