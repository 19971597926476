import store from '@/store'

export class Editable {
  target = null
  zone = null
  location = null
  touched = false

  _initialLatLngs = null
  _initialLatLng = null

  constructor ({ target, zone, location }) {
    this.target = target
    this.zone = zone
    this.location = location

    this._saveInitialState()
    if (this.zone) {
      target.editing.enabled() ? target.editing.disable() : target.editing.enable()
    } else if (this.location) {
      // Location editing is configured from the component
    }
  }

  submit () {
    if (this.zone) {
      return this._submitZone()
    } else if (this.location) {
      return this._submitLocation()
    }
    this.target.editing.disable()
  }

  markAsTouched () {
    this.touched = true
  }

  cancel () {
    if (this.zone) {
      this.target.setLatLngs(this._initialLatLngs)
      this.target.editing.disable()
    } else if (this.location) {
      this.target.setLatLng(this._initialLatLng)
      // Location editing is configured from the component
    }
  }

  _saveInitialState () {
    if (this.zone) {
      this._initialLatLngs = JSON.parse(JSON.stringify(this.target.getLatLngs()[0]))
    } else if (this.location) {
      this._initialLatLng = JSON.parse(JSON.stringify(this.target.getLatLng()))
    }
  }

  _submitZone () {
    const latLngs = this.target
      .getLatLngs()[0]
      .map(latLng => ([latLng.lat, latLng.lng]))
    return store.dispatch('zones/saveZone', { ...this.zone, latLngs })
  }

  _submitLocation () {
    const coords = this.target.getLatLng()
    const editedLocation = { ...this.location, lat: coords.lat, lng: coords.lng }
    return store.dispatch('locations/saveLocation', editedLocation)
  }
}
