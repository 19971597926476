<template lang="pug">
b-field(:label='labelRow || label' label-position='on-border')
  b-select(
    :id='id'
    :required='required'
    :disabled='disabled'
    :placeholder='labelRow || label'
    validation-message='Este campo es requerido'
    :loading='loading'
    @input='emitValues'
    v-model='rawValue'
  )
    option(value) Seleccione uno
    option(v-for='item in finalItems' :key='item.id' :value='item.id') {{ item.description }}
</template>

<script>
export default {
  props: {
    value: {},
    required: { type: Boolean },
    disabled: { type: Boolean },
    loading: { type: Boolean },
    id: { type: String },
    label: { type: String },
    labelRow: { type: String },
    enum: { type: Object },
    enumNames: { type: Object },

    items: { type: Array },
    exclude: { type: Array },
    valueName: { type: String, default: 'id' },
    showName: { type: String, default: 'name' }
  },
  data () {
    return {
      rawValue: this.value
    }
  },
  watch: {
    value () {
      this.rawValue = this.value
    }
  },
  computed: {
    finalItems () {
      const exclude = this.exclude || []
      if (this.enum && this.enumNames) {
        return Object.values(this.enum).map(id => ({ id, description: this.enumNames[id] })).filter(item => !exclude.includes(item.id))
      }
      if (this.items) {
        return this.items.map(item => ({ id: item[this.valueName], description: item[this.showName] })).filter(item => !exclude.includes(item.id))
      }
      return []
    }
  },
  methods: {
    emitValues ($event) {
      this.$emit('input', $event || null)
    }
  }
}
</script>
