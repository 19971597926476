<template lang="pug">
.modal-card(:style='{ width }')
  header.modal-card-head(v-if='title')
    p.modal-card-title {{ primaryTitle || title }}
    button(
      type='button'
      class='delete'
      @click="close"
    )
  section.modal-card-body
    slot
  footer.modal-card-foot(v-if='cancelText || submitText')
    button.button(type='button' @click='$emit("close")') {{ cancelText }}
    button.button.is-primary {{ submitText }}
</template>

<script>
export default {
  props: {
    title: { type: String },
    cancelText: { type: Boolean },
    submitText: { type: Boolean },
    width: { type: [String, Number], default: 'auto' }
  },
  data () {
    return {
      primaryTitle: null
    }
  },
  methods: {
    close () {
      if (this.$parent.close) {
        this.$parent.close()
      } else if (this.$parent.$parent.close) {
        this.$parent.$parent.close()
      }
    },
    setTitle (title) {
      this.primaryTitle = title
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~@/scss/variables/colors';

.modal-card {
  color: $text-color;
}
</style>
