<template lang="pug">
.field
  label.label(:for='field' :class='{ required }') {{ label }}
  .control
    slot
</template>

<script>
export default {
  props: {
    label: { type: String },
    field: { type: String },
    required: { type: Boolean }
  }
}
</script>

<style lang="scss" scoped>
.required {
  &::after {
    content: '*';
    margin-left: 10px;
    opacity: 0.5;
    transition: opacity 0.3s;
  }

  &:hover::after {
    opacity: 1;
  }
}
</style>
