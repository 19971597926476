import { userPermissions } from '@/constants/enums'
import MainMap from '../views/Map.vue'

export const home = {
  path: '/',
  name: 'home',
  component: () => import(/* webpackChunkName: "home" */ '../views/Home.vue'),
  meta: { requiresAuth: true,
    layout: 'default',
    title: 'Home',
    icon: 'map'
  }
}

export const login = {
  path: '/login',
  name: 'login',
  component: () => import(/* webpackChunkName: "login" */ '../views/Login.vue'),
  meta: {}
}

export const withoutPermissions = {
  path: '/without-permissions',
  name: 'without-permissions',
  component: () => import(/* webpackChunkName: "without-permissions" */ '../views/WihoutPermissions.vue'),
  meta: {
    layout: 'default',
    title: 'No tienes permisos',
    icon: 'map'
  }
}

export const ewsDashboard = {
  path: '/ews',
  name: 'ews-dashboard',
  component: () => import(/* webpackChunkName: "ews-dashboard" */ '../views/ews/Dashboard.vue'),
  meta: {
    requiresAuth: true,
    layout: 'default',
    permission: userPermissions.GET_EWS,
    title: 'Tablero SAT',
    icon: 'chalkboard-teacher'
  }
}

export const contractsList = {
  path: '/contracts',
  name: 'contracts',
  component: () => import(/* webpackChunkName: "contracts" */ '../views/contracts/Contracts.vue'),
  meta: { requiresAuth: true,
    layout: 'default',
    title: 'Contratos',
    icon: 'book',
    permission: userPermissions.GET_CONTRACTS
  }
}

export const contractDetail = {
  path: '/contracts/:id',
  name: 'contract',
  component: () => import(/* webpackChunkName: "contract" */ '../views/contracts/Contract.vue'),
  meta: {
    requiresAuth: true,
    layout: 'default'
  }
}

export const invoicesList = {
  path: '/invoices',
  name: 'invoices',
  component: () => import(/* webpackChunkName: "invoices" */ '../views/invoices/Invoices.vue'),
  meta: {
    requiresAuth: true,
    layout: 'default',
    title: 'Facturas',
    icon: 'file-invoice-dollar',
    permission: userPermissions.GET_CONTRACTS_INVOICES
  }
}

export const invoiceDetail = {
  path: '/invoice/:id',
  name: 'invoice',
  component: () => import(/* webpackChunkName: "invoice" */ '../views/invoices/Invoice.vue'),
  meta: {
    requiresAuth: true,
    layout: 'default'
  }
}

export const createInvoice = {
  path: '/invoice/create',
  name: 'editable-invoice',
  component: () => import(/* webpackChunkName: "editable-invoice" */ '../views/invoices/EditableInvoice.vue'),
  meta: {
    requiresAuth: true,
    layout: 'default'
  }
}

export const pendingInvoices = {
  path: '/pending-invoices',
  name: 'pending-invoices',
  component: () => import(/* webpackChunkName: "pending-invoices" */ '../views/bot/PendingInvoices.vue'),
  meta: {}
}

export const reportInOutZones = {
  path: '/reports/in-out-zones',
  name: 'report-in-out-zones',
  component: () => import(/* webpackChunkName: "report-in-out-zones" */ '../views/reports/InOutZones.vue'),
  meta: {
    requiresAuth: true,
    layout: 'default',
    title: 'Entrada o salida de zonas',
    subtitle: 'Este reporte le permitirá conocer si uno o varios equipos salen o ingresan de una zona creada.',
    icon: 'draw-polygon',
    permission: userPermissions.GET_DEVICE_REPORTS
  }
}

export const reportSpeed = {
  path: '/reports/speed',
  name: 'report-speed',
  component: () => import(/* webpackChunkName: "report-speed" */ '../views/reports/Speed.vue'),
  meta: {
    requiresAuth: true,
    layout: 'default',
    title: 'Velocidad',
    icon: 'tachometer-alt',
    permission: userPermissions.GET_DEVICE_REPORTS
  }
}

export const reportTypes = {
  path: '/reports/types',
  name: 'report-types',
  component: () => import(/* webpackChunkName: "report-types" */ '../views/reports/ReportTypes.vue'),
  meta: {
    requiresAuth: true,
    layout: 'default',
    title: 'Tipos de eventos',
    subtitle: 'Consulte los eventos generados por uno o varios equipos',
    icon: 'exclamation',
    permission: userPermissions.GET_DEVICE_REPORTS
  }
}

export const locations = {
  path: '/locations',
  name: 'locations',
  component: () => import(/* webpackChunkName: "locations" */ '../views/locations/Locations.vue'),
  meta: {
    requiresAuth: true,
    layout: 'default',
    title: 'Sitios',
    icon: 'map-marker'
  }
}

export const inpointSchedules = {
  path: '/inpoint/schedules',
  name: 'inpointSchedules',
  component: () => import(/* webpackChunkName: "inpoint-schedules" */ '../views/inpoint/Schedules.vue'),
  meta: {
    requiresAuth: true,
    layout: 'default',
    title: 'Programaciones',
    icon: 'clock'
  }
}

export const inpointSchedule = {
  path: '/inpoint/schedules/:scheduleId',
  name: 'inpointSchedule',
  component: () => import(/* webpackChunkName: "inpoint-schedule" */ '../views/inpoint/Schedule.vue'),
  meta: {
    requiresAuth: true,
    layout: 'default',
    title: 'Programación',
    icon: 'map'
  }
}

export const inpointNewSchedule = {
  path: '/inpoint/schedules/create',
  name: 'inpointNewSchedule',
  component: () => import(/* webpackChunkName: "inpoint-new-schedule" */ '../views/inpoint/Schedule.vue'),
  meta: {
    requiresAuth: true,
    layout: 'default',
    title: 'Programación',
    icon: 'map'
  }
}

export const inpointVisits = {
  path: '/inpoint/visits',
  name: 'inpointVisits',
  component: () => import(/* webpackChunkName: "inpoint-visits" */ '../views/inpoint/Visits.vue'),
  meta: {
    requiresAuth: true,
    layout: 'default',
    title: 'Visitas',
    icon: 'map-marked'
  }
}

export const inPoint = {
  path: '/inpoint',
  name: 'inpoint-home',
  component: () => import(/* webpackChunkName: "inpoint-home" */ '../views/inpoint/Home.vue'),
  meta: {
    requiresAuth: true,
    layout: 'inpoint',
    title: 'InPoint',
    icon: 'map'
  }
}

export const inPointRegisterLocation = {
  path: '/inpoint/register-location',
  name: 'inpoint-register-location',
  component: () => import(/* webpackChunkName: "inpoint-register-location" */ '../views/inpoint/RegisterLocation.vue'),
  meta: {
    requiresAuth: true,
    layout: 'inpoint',
    title: 'Registrar ubicación',
    icon: 'map'
  }
}

export const shippingShipments = {
  path: '/shipping/shipments',
  name: 'shippingShipments',
  component: () => import(/* webpackChunkName: "shipping-shipments" */ '../views/shipping/Shipments.vue'),
  meta: {
    requiresAuth: true,
    layout: 'default',
    permission: userPermissions.GET_SHIPMENTS,
    title: 'Shipments',
    icon: 'truck'
  }
}

export const clientsList = {
  path: '/clients',
  name: 'clients',
  component: () => import(/* webpackChunkName: "clients" */ '../views/clients/Clients.vue'),
  meta: {
    requiresAuth: true,
    layout: 'default',
    permission: userPermissions.CLIENTS_GET,
    title: 'Clientes',
    icon: 'users'
  }
}

export const clientDetail = {
  path: '/clients/:id',
  name: 'client',
  component: () => import(/* webpackChunkName: "client" */ '../views/clients/Client.vue'),
  meta: {
    requiresAuth: true,
    layout: 'default'
  }
}

export const command = {
  path: '/command/:commandId/:deviceId',
  name: 'command',
  component: () => import(/* webpackChunkName: "command" */ '../views/command/Command.vue'),
  meta: {
    requiresAuth: true,
    layout: 'basic',
    title: 'Comando'
  }
}

export const adminDevices = {
  path: '/devices',
  name: 'devices-admin',
  component: () => import(/* webpackChunkName: "devices-list" */ '../views/devices/DevicesAdminList.vue'),
  meta: { requiresAuth: true, layout: 'default', title: 'Equipos', icon: 'boxes' }
}

export const adminDeviceDetails = {
  path: '/devices/:id',
  name: 'device-admin-detail',
  component: () => import(/* webpackChunkName: "device-detail" */ '../views/devices/DeviceDetail.vue'),
  meta: { requiresAuth: true, layout: 'default', title: 'Equipo', icon: 'map' }
}

export const map = {
  path: '/map',
  name: 'map',
  component: MainMap,
  meta: { requiresAuth: true, layout: 'default', title: 'Mapa', icon: 'map', reducedMenu: true }
}

export const rulesList = {
  path: '/rules',
  name: 'rules',
  component: () => import(/* webpackChunkName: "rules" */ '../views/rules/Rules.vue'),
  meta: {
    requiresAuth: true,
    layout: 'default',
    title: 'Reglas',
    icon: 'bolt'
  }
}

export const rulesZoneCreate = {
  path: '/rules/zone/create',
  name: 'createZoneRule',
  component: () => import(/* webpackChunkName: "zone-rule" */ '../views/rules/ZoneRule.vue'),
  meta: { requiresAuth: true,
    layout: 'default',
    title: 'Regla de una zona',
    icon: 'map'
  }
}

export const rulesZoneEdit = {
  path: '/rules/zone/:id',
  name: 'editZoneRule',
  component: () => import(/* webpackChunkName: "zone-rule" */ '../views/rules/ZoneRule.vue'),
  meta: { requiresAuth: true,
    layout: 'default',
    title: 'Editar regla de una zona',
    icon: 'map'
  }
}

export const rulesOdometerCreate = {
  path: '/rules/odometer/create',
  name: 'createOdometerRule',
  component: () => import(/* webpackChunkName: "odometer-rule" */ '../views/rules/OdometerRule.vue'),
  meta: { requiresAuth: true,
    layout: 'default',
    title: 'Regla basada en el odometro',
    icon: 'map'
  }
}

export const rulesOdometerEdit = {
  path: '/rules/odometer/:id',
  name: 'editOdometerRule',
  component: () => import(/* webpackChunkName: "odometer-rule" */ '../views/rules/OdometerRule.vue'),
  meta: { requiresAuth: true,
    layout: 'default',
    title: 'Editar regla basada en el odometro',
    icon: 'map'
  }
}

export const rulesTimeCreate = {
  path: '/rules/time/create',
  name: 'createTimeRule',
  component: () => import(/* webpackChunkName: "time-rule" */ '../views/rules/TimeRule.vue'),
  meta: { requiresAuth: true,
    layout: 'default',
    title: 'Regla basada en el tiempo',
    icon: 'map'
  }
}

export const rulesTimeEdit = {
  path: '/rules/time/:id',
  name: 'editTimeRule',
  component: () => import(/* webpackChunkName: "time-rule" */ '../views/rules/TimeRule.vue'),
  meta: { requiresAuth: true,
    layout: 'default',
    title: 'Editar regla basada en el tiempo',
    icon: 'map'
  }
}

export const rulesUsageCreate = {
  path: '/rules/usage/create',
  name: 'createUsageRule',
  component: () => import(/* webpackChunkName: "usage-rule" */ '../views/rules/UsageRule.vue'),
  meta: { requiresAuth: true,
    layout: 'default',
    title: 'Regla basada en el uso del equipo',
    icon: 'map'
  }
}

export const rulesUsageEdit = {
  path: '/rules/usage/:id',
  name: 'editUsageRule',
  component: () => import(/* webpackChunkName: "usage-rule" */ '../views/rules/UsageRule.vue'),
  meta: { requiresAuth: true,
    layout: 'default',
    title: 'Editar regla basada en el uso del equipo',
    icon: 'map'
  }
}
