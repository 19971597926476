import { acimuitDirections } from './enums'

export const deviceIsStopped = speed => speed === null || speed === 0

export const getDeviceDirection = (speed, acimutDirection) => {
  if (deviceIsStopped(speed)) return 'Detenido'
  if (acimutDirection === 'WITHOUT_INFORMATION') return acimuitDirections[acimutDirection]
  return acimuitDirections[acimutDirection] ? `Se dirige al ${acimuitDirections[acimutDirection]}` : ''
}

export const isMobile = () => {
  return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)
}
