<template lang="pug">
.page-title-container.mt-1.p-2
  h1.title.page-title {{ title || $route.meta.title }}
    small.subsitle(v-if='subtitle || $route.meta.subtitle') {{ subtitle || $route.meta.subtitle }}
  .page-title-options
    slot
</template>

<script>
export default {
  props: {
    title: { type: String },
    subtitle: { type: String }
  }
}
</script>

<style lang="scss" scoped>
.page-title-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.page-title {
  margin: 0;
}

.page-title-options {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.subsitle {
  font-size: 0.5em;
  font-weight: normal;
  display: block;
  margin-top: 8px;
  font-style: italic;
}
</style>
