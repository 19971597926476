<template lang="pug">
.tools-container(:class='{ disabled }')
  button.tool-button(@click='$emit("create")' type='button' :disabled='disabled' v-if='allowToCreate')
    b-icon(pack='fa' icon='plus')
  button.tool-button(@click='$emit("reload")' type='button' :disabled='disabled')
    b-icon(pack='fa' icon='redo')
  button.tool-button(@click='$emit("check")' type='button' :disabled='disabled')
    b-icon(pack='fa' icon='check-double')
  button.tool-button(@click='$emit("remove")' type='button' :disabled='disabled' v-if='allowToRemove')
    b-icon(pack='fa' icon='trash')
</template>

<script>
export default {
  props: {
    disabled: { type: Boolean, default: false },
    allowToCreate: { type: Boolean, default: false },
    allowToRemove: { type: Boolean, default: false }
  }
}
</script>

<style lang="scss" scoped>
.tools-container {
  display: flex;
}
.tool-button {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px;
  border: none;

  @media only screen and (max-width: 900px) {
    padding: 10px;
  }
}
</style>
