<template lang="pug">
.device-sidebar(:class='{ expanded }')
  .device-sidebar-tape.flex-column(v-if='selectedDevice' :class='{ expanded }')
    .details-heder
      map-crosshair
      button.header-btn.flex(@click='toggleState')
        device-status.device-status(:device='selectedDevice')
        .header-btn-content.flex-column
          .flex
            span.header-title(:title='selectedDevice.name') {{ selectedDevice.name }}
          span.header-subtitle(:title='subtitle') {{ subtitle }}
        icon(icon='times' @click.stop='toggleState' v-if='expanded')
  .device-sidebar-body(:class='{ expanded }')
    .detail-container.flex-column(v-if='expanded')
      .card.flex-column.detail
        h2.detail-title Estado
        .detail-section.flex
          device-status.device-status(:device='selectedDevice')
          span.detail-section-text {{ deviceStatus }}
          span.detail-section-text {{ activeDate }}
          a(@click='statusHelpOpened = !statusHelpOpened')
            b-tooltip(label='Más info' position='is-bottom')
              icon(icon='info-circle')
        div.box(v-if='statusHelpOpened')
          .is-size-7
            icon(icon='info-circle')
            span.ml-2 Esta es la fecha en la que el equipo GPS tuvo contacto con el servidor. Un equipo puede estar en línea (conectado con el servidor) pero no tener señal satelital para establecer su ubicación.
            br
            br
            a(@click='statusHelpOpened = false') Cerrar
      .card.flex-column.detail
        h2.detail-title Última ubicación conocida
        .detail-section-v.flex-column
          span.detail-section-text.flex(v-if='lastReport' :title='lastReport')
            b-icon(pack='fa' icon='clock')
            | {{ lastReport }}
          span.detail-section-text.flex
            b-icon(pack='fa' icon='map-marker')
            | {{ devicePosition }}
          span.detail-section-text.flex(v-if='deviceAddress')
            b-icon(pack='fa' icon='map-marked')
            | {{ deviceAddress }}
          span.detail-section-text.flex
            b-icon(pack='fa' icon='shipping-fast')
            | {{ deviceSpeed }}
      .card.flex-column.detail(v-if='showCommands')
        h2.detail-title Comandos
        .detail-section.flex
          b-button.command-btn(
            v-for='item in enabledCommands'
            outlined
            type='is-primary'
            size='is-small'
            :key='`command-btn-${item.id}`'
            @click='sendCommand(item)'
          )
            | {{ item.command }}
      devices-reports
      .card.flex-column.detail
        h2.detail-title Más información
        property-list(:data='selectedDevice' :properties='moreData' type='list' default-value='[Sin información]')
      .card.flex-column.detail
        h2.detail-title Otra información
        property-list(:data='selectedDevice' :properties='otherData' type='list' default-value='[Sin información]')
</template>

<script>
import { mapState } from 'vuex'
import router from '@/router'
import DeviceStatus from '@/components/devices/DeviceStatus.vue'
import DevicesReports from '@/components/devices/DevicesReports.vue'
import PropertyList from '@/components/PropertyList'
import { openCommandModal } from '@/components/shared/modals/CommandModal'
import MapCrosshair from '@/components/map/MapCrosshair'
import dateDistanceStrict from '@/filters/dateDistanceStrict.filter'
import datetime from '@/filters/datetime.filter'
import { deviceStatusEnum, deviceStatusDescriptions } from '@/constants/enums'
import { getDeviceDirection, isMobile } from '@/constants/utils'
import { adminDeviceDetails } from '@/router/routes'

const STATES = {
  expanded: 'expanded',
  collapsed: 'collapsed'
}

const MORE_DATA = [
  { field: 'fix', label: 'Señal satelital', renderFn: value => value ? 'Sí' : 'No' },
  { field: 'acimutDirection', label: 'Rumbo', renderFn: (value, device) => getDeviceDirection(device.speed, value) },
  { field: 'altitude', label: 'Altitud', renderFn: value => value >= 0 ? `${value} MSNM` : '-' }
]

const OTHER_DATA = [
  { field: 'imei', label: 'IMEI', linkTo: data => ({ name: adminDeviceDetails.name, params: { id: data.id } }) },
  { field: 'registerDate', label: 'Registrado' }
]

export default {
  components: { DeviceStatus, DevicesReports, PropertyList, MapCrosshair },
  data () {
    return {
      expanded: false,
      otherData: OTHER_DATA,
      moreData: MORE_DATA,
      statusHelpOpened: false,
      adminDeviceDetails,
      reportDate: null,
      activeDate: null,
      updateDateInterval: null
    }
  },
  mounted () {
    this.updateDateInterval = setInterval(() => {
      this.updateDateReferences()
    }, 5000)
  },
  destroyed () {
    clearInterval(this.updateDateInterval)
    this.updateDateInterval = null
  },
  computed: {
    ...mapState({
      selectedDevice: state => state.devices.selectedDevice,
      selectedDeviceDetails: state => state.devices.selectedDeviceDetails,
      detailsLoading: state => state.devices.detailsLoading,
      mapCenter: state => state.map.center
    }),
    open () {
      return !!this.selectedDevice
    },
    stateClass () {
      return this.expanded ? STATES.expanded : STATES.collapsed
    },
    subtitle () {
      if (!this.reportDate) {
        return ''
      }
      if (this.deviceAddress) {
        if (this.deviceAddress === '...') return `${this.reportDate} ...`
        return `${this.reportDate} en ${this.deviceAddress}`
      }
      return this.reportDate
    },
    lastReport () {
      return this.reportDate ? `${this.reportDate} (${datetime(this.selectedDevice.reportDate)})` : '[Sin información de fecha]'
    },
    deviceStatus () {
      return deviceStatusDescriptions[this.selectedDevice.status] || deviceStatusDescriptions[deviceStatusEnum.Unknown]
    },
    devicePosition () {
      const lat = this.selectedDevice.lat ? Number.parseFloat(this.selectedDevice.lat).toFixed(8) : '[No disponible]'
      const lng = this.selectedDevice.lng ? Number.parseFloat(this.selectedDevice.lng).toFixed(8) : '[No disponible]'
      if (isNaN(lat) || isNaN(lng)) {
        return 'El equipo no ha obtenido señal GPS'
      }
      const accuracy = this.selectedDevice.accuracy
      const accuracyText = (accuracy === -1 || accuracy === null) ? '' : `(${accuracy}M)`
      return `${lat}, ${lng} ${accuracyText}`
    },
    deviceSpeed () {
      if (!this.selectedDevice.speed) {
        return 'En reposo'
      }
      return `${this.selectedDevice.speed} Km/H`
    },
    deviceAddress () {
      if (this.detailsLoading) return '...'
      return this.selectedDeviceDetails?.address
    },
    enabledCommands () {
      return this.selectedDeviceDetails?.enabledCommands || []
    },
    showCommands () {
      return !!this.enabledCommands.length
    }
  },
  watch: {
    selectedDevice (current, previous) {
      if (current && previous && current.id === previous.id) {
        const [latCenter, lngCenter] = this.mapCenter
        const { lat, lng } = current
        // Round to 6 decimals and compare if the map is cenntered in there
        const tmplat = Math.round(latCenter * 1000000) / 1000000
        const tmplng = Math.round(lngCenter * 1000000) / 1000000
        const tmplatC = Math.round(lat * 1000000) / 1000000
        const tmplngC = Math.round(lng * 1000000) / 1000000
        if (tmplat === tmplatC && tmplng === tmplngC) {
          this.expanded = true
        }
      }
      this.updateDateReferences()
    }
  },
  methods: {
    updateDateReferences () {
      this.reportDate = this.selectedDevice && this.selectedDevice.reportDate ? dateDistanceStrict(this.selectedDevice.reportDate, true) : null
      this.activeDate = this.selectedDevice && this.selectedDevice.activeDate ? `- ${dateDistanceStrict(this.selectedDevice.activeDate, true)}` : ''
    },
    toggleState () {
      this.expanded = !this.expanded
    },
    sendCommand (command) {
      if (isMobile()) {
        router.push({ name: 'command', params: { commandId: command?.id, deviceId: this.selectedDevice.id } })
      } else {
        openCommandModal({
          modal: this.$buefy.modal,
          parent: this,
          props: {
            commandId: `${command?.id}`,
            deviceId: `${this.selectedDevice.id}`,
            isModal: true
          }
        })
      }
    },
    onDeviceMarkerClicked (device) {
      if (this.selectedDevice && this.selectedDevice.id === device.id) {
        this.expanded = true
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~@/scss/variables/colors';

$tape-spacing: 4px;
$tape-height: 43px;
$mobile-breakpoint: 415px;

.device-sidebar {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 1000;
  transform: translateX(350px);
  transition: transform 0ms;

  width: 100%;
  max-width: 350px;

  &.expanded {
    transform: translateX(0);
    transition: transform 200ms ease-in;
  }

  @media only screen and (max-width: $mobile-breakpoint) {
    width: 100%;
    max-width: none;
  }
}

.device-sidebar-body {
  border-radius: 5px 0 0 0;
  height: calc(100% - #{$tape-height} - #{$tape-spacing} - #{$tape-spacing});
  overflow-y: auto;
}

.device-sidebar-tape {
  margin-top: $tape-spacing;
  margin-bottom: $tape-spacing;
  height: $tape-height;
  width: 100%;
  max-width: 350px;
  border-radius: 5px 0 0 5px;
  transform: translateX(-350px);

  &.expanded {
    transform: translateX(0);
    position: initial;
    max-width: initial;
    width: initial;
  }

  @media only screen and (max-width: $mobile-breakpoint) {
    max-width: none;
  }

}

.details-heder {
  display: grid;
  grid-template-columns: 45px auto;
  column-gap: 4px;
}

.collapsed {
  height: 60px;
}

.header-btn {
  align-items: center;
  border: 1px solid $main-color;
  background: $background-gray;
  border-radius: 5px 0 0 5px;
  justify-content: start;
  padding: 4px;
  cursor: pointer;
}

.header-btn-content {
  align-items: flex-start;
}

.header-subtitle {
  text-overflow: ellipsis;
  overflow: hidden;
  text-align: left;
  white-space: nowrap;
  width: 240px;
}

.device-status {
  height: 14px;
  width: 14px;
  margin: 0 8px;
}

.header-title {
  font-size: 14px;
  font-weight: 700;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.detail-container {
  background-color: $background-gray;
  padding: 4px;
}

.detail {
  margin-bottom: 4px;
  padding: 8px;
}

.detail-title {
  font-size: 10px;
  line-height: 12px;
  margin-bottom: 4px;
}

.detail-section {
  align-items: center;
}

.detail-section-v {
  align-items: start;
}

.detail-section-text {
  font-size: 14px;
  margin-right: 8px;
}

.command-btn {
  margin-right: 4px;
}
</style>
