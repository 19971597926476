const prefix = 'samm_'
const getItem = key => {
  return new Promise(resolve => {
    const value = localStorage.getItem(prefix + key)
    resolve(value ? JSON.parse(value) : undefined)
  })
}

const setItem = (key, value) => {
  return new Promise(resolve => {
    localStorage.setItem(prefix + key, JSON.stringify(value))
    resolve()
  })
}

const removeItem = key => {
  return new Promise(resolve => {
    localStorage.removeItem(prefix + key)
    resolve()
  })
}

export default {
  getItem,
  setItem,
  removeItem
}
