<template lang="pug">
span
  indicator-button(
    title='Equipos desconectados'
    icon='stopwatch'
    :unreaded='inactiveDevices'
    @click='isCommandsModalActive = true'
  )
  b-modal(v-model='isCommandsModalActive' has-modal-card)
    modal-card(title='Equipos desconectados')
      div(v-for='(groupDevices, key) in groups')
        h3 {{ key }}
        devices-list(:devices='groupDevices')
</template>

<script>
import { mapState } from 'vuex'

import { differenceInMinutes } from 'date-fns'
import differenceInYears from 'date-fns/differenceInYears'
import differenceInMonths from 'date-fns/differenceInMonths'
import differenceInDays from 'date-fns/differenceInDays'
import differenceInWeeks from 'date-fns/differenceInWeeks'
import differenceInHours from 'date-fns/differenceInHours'
import DevicesList from '@/components/home/DevicesList'
import IndicatorButton from '@/components/shared/IndicatorButton'
import { deviceStatusEnum } from '@/constants/enums'

export default {
  components: {
    DevicesList,
    IndicatorButton
  },
  data () {
    return {
      isCommandsModalActive: false
    }
  },
  computed: {
    ...mapState({
      loading: state => state.devices.loading,
      error: state => state.devices.error,
      devices: state => state.devices.devices
    }),
    inactiveDevices () {
      return this.devices.filter(device => device.status === deviceStatusEnum.Inactive).length
    },
    groups () {
      return this.devices.reduce((memo, device) => {
        if (!device.activeDate) return memo
        let key = 'Resto'
        if (differenceInYears(new Date(), new Date(device.activeDate)) > 1) {
          key = 'Más de un año'
        } else if (differenceInMonths(new Date(), new Date(device.activeDate)) > 1) {
          key = 'Más de un mes'
        } else if (differenceInDays(new Date(), new Date(device.activeDate)) > 15) {
          key = 'Más de 15 días'
        } else if (differenceInWeeks(new Date(), new Date(device.activeDate)) > 1) {
          key = 'Más de 1 semana'
        } else if (differenceInDays(new Date(), new Date(device.activeDate)) > 2) {
          key = 'Más de 2 días'
        } else if (differenceInDays(new Date(), new Date(device.activeDate)) > 1) {
          key = 'Más de 1 día'
        } else if (differenceInHours(new Date(), new Date(device.activeDate)) > 10) {
          key = 'Más de 10 horas'
        } else if (differenceInHours(new Date(), new Date(device.activeDate)) > 5) {
          key = 'Más de 5 horas'
        } else if (differenceInHours(new Date(), new Date(device.activeDate)) > 1) {
          key = 'Más de 1 hora'
        } else if (differenceInMinutes(new Date(), new Date(device.activeDate)) > 10) {
          key = 'Más de 10 minutos'
        }

        if (memo[key]) {
          memo[key].push(device)
        } else {
          memo[key] = []
        }
        return memo
      }, {})
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~@/scss/variables/colors';

.commands-indicator-button {
  border: none;
  height: 40px;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: none;
  color: inherit;
  position: relative;
}

.pending-count {
  position: absolute;
  right: 0;
  top: 0;
  background: red;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.commands-list {
  color: $text-color;
}
</style>
