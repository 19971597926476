<template lang="pug">
b-field(:label='label' label-position='on-border')
  b-timepicker(
    :placeholder='placeholder'
    :icon='icon'
    :enable-seconds="enableSeconds"
    hour-format="24"
    locale='es-MX'
    v-model='rawValue'
    @input='valueChanged'
  )
</template>

<script>
import { format } from 'date-fns'

const tryToParseValue = value => {
  if (value instanceof Date) return value
  if (typeof value === 'string') {
    return new Date(format(new Date(), 'yyyy-MM-dd') + 'T' + value)
  }
  return value
}
const formatOutputValue = value => {
  if (value) {
    const formatted = format(new Date(value), 'HH:mm:ss')
    return formatted
  }
  return value
}

export default {
  props: {
    label: { type: String },
    icon: { type: String },
    type: { type: String, default: 'text' },
    required: { type: Boolean },
    disabled: { type: Boolean },
    readonly: { type: Boolean },
    enableSeconds: { type: Boolean },
    placeholder: { type: String },
    maxlength: { type: Number },
    value: {}
  },
  data () {
    return {
      rawValue: tryToParseValue(this.value)
    }
  },
  watch: {
    value () {
      this.rawValue = tryToParseValue(this.value)
    }
  },
  methods: {
    valueChanged () {
      this.$emit('input', formatOutputValue(this.rawValue))
    }
  }
}
</script>
