<template lang="pug">
.card.flex-column.detail
  h2.detail-title Historial de recorrido
  .flex
    b-button.report-btn(outlined type='is-primary' size='is-small' @click='onGetReportLastHour' :loading='isLoading')
      | Últ. hora
    b-button.report-btn(outlined type='is-primary' size='is-small' @click='onGetReportToday' :loading='isLoading')
      | Hoy
    b-button.report-btn(outlined type='is-primary' size='is-small' @click='onGetReportYesterday' :loading='isLoading')
      | Ayer
    b-button.report-btn(outlined type='is-primary' size='is-small' @click='toggleOpenForm' :loading='isLoading')
      | Otras
  .flex-column.mt(v-if='showForm')
    .flex
      b-field.mr-2(label='Desde' label-position='on-border')
        b-datetimepicker(
          v-model='startDate'
          placeholder="Seleccionar fecha"
          icon-pack='fa'
          size='is-small'
          position='is-top-left'
          editable
          mobile-native
        )
      b-field(label='Hasta' label-position='on-border')
        b-datetimepicker(
          v-model='endDate'
          placeholder="Seleccionar fecha"
          icon-pack='fa'
          size='is-small'
          position='is-top-left'
          editable
          mobile-native
          :min-datetime='endDatetimeValidations.minDatetime'
          :max-datetime='endDatetimeValidations.maxDatetime'
        )
    b-button.report-btn(outlined type='is-primary' size='is-small' @click='onGetReportForm' :disabled='disableGenerateReport')
      | Generar reporte
  .flex-column(v-if='showReports')
    h3.detail-subtitle Reportes generados
    .flex-colums.container-reports
      .card.container-report(v-for='report in reports' :key='report.id' :style='getStyleReport(report)')
        .flex.report-header
          .flex
            b-checkbox(
              v-model='report.selected'
              size='is-small'
              :input-id='report.id'
              @input='onSelecting(report.id, $event)'
            )
            span.device-name {{ report.deviceName }}
          .flex
            b-button.mr-1(size='is-small' type='button' @click='toggleShowDetails(report.id)' :icon-right='report.showDetails ? "arrow-up" : "arrow-down"')
            export-button.button-with-icon.mr-1(:data='report.locations' hide-label :columns='exportColumns' :name='getReportName(report.id)' size='is-small' :show-icon='true')
            b-button(size='is-small' @click='deleteReport(report.id)' icon-right='times')
        property-list(:data='report' :properties='reportData' type='list' size='x-small')
        .details.ml-1.mt-1(v-if='report.showDetails')
          .detail-location(v-for='location in report.locations' :key='location.id')
            span.detail-text {{ location.reportDate | datetime }}
            .is-flex.is-align-items-center
              button.detail-btn-location(type='button' @click='goToLocation({ location, report })')
                b-icon.ml-2.detail-icon(pack='fa' icon='map-marker')
              template(v-if='location.isStopped')
                b-tooltip(
                  :label='`Se detuvo ${minutesDurationFilter(location.stoppedMinutes)}`'
                  :delay='tooltipOptions.delay'
                  :type='tooltipOptions.type.default'
                  :size="tooltipOptions.size.small"
                  position='is-left'
                )
                  .is-flex.is-align-items-center
                    b-icon.ml-2.detail-icon(pack='fa' icon='stop-circle')
                    span.detail-text {{ location.stoppedMinutes }}mins
</template>

<script>
import { mapState, mapActions } from 'vuex'
import { addHours, addDays, startOfToday, endOfToday, startOfDay, endOfDay, differenceInHours } from 'date-fns'
import toastService from '@/services/toast.service'
import PropertyList from '@/components/PropertyList'
import datetime from '@/filters/datetime.filter'
import { rangeInHoursForDeviceReports, tooltipOptions } from '@/constants/configs'
import { dateToServer } from '@/constants/formats'
import minutesDurationFilter from '@/filters/minutesDuration.filter'

const REPORT_DATA = [
  { field: 'startDate', label: 'Desde', renderFn: value => datetime(value) },
  { field: 'endDate', label: 'Hasta', renderFn: value => datetime(value) },
  { field: 'distance', label: 'Recorrido', renderFn: value => `${parseFloat(isNaN(value) ? 0 : (value / 1000)).toFixed(1)} Kilómetros aprox.` },
  { field: 'speedAverage', label: 'Velocidad promedio', renderFn: value => `${parseFloat(isNaN(value) ? 0 : value).toFixed(1)} Km/H` },
  { field: 'stopsCount', label: 'Paradas', renderFn: value => `${parseFloat(isNaN(value) ? 0 : value)}` },
  { field: 'stoppedMinutes', label: 'Tiempo detenido', renderFn: value => minutesDurationFilter(value) },
  { field: 'consumption', label: 'Consumo', renderFn: value => `${parseFloat(isNaN(value) ? 0 : value).toFixed(2)} galones` }
]

const EXPORT_COLUMNS = [
  { headerName: 'Fecha y hora', field: 'reportDate' },
  { headerName: 'Latitud', field: 'lat' },
  { headerName: 'Longitud', field: 'lng' },
  { headerName: 'Velocidad', field: 'speed' },
  { headerName: 'Detenido', field: 'isStopped' },
  { headerName: 'Sitio cercano', field: 'nearbyZone' },
  { headerName: 'Batería', field: 'battery' },
  { headerName: 'Observacion', field: 'message' }
]

export default {
  components: { PropertyList },
  data () {
    return {
      showForm: false,
      startDate: new Date(),
      endDate: new Date(),
      reportData: REPORT_DATA,
      exportColumns: EXPORT_COLUMNS,
      tooltipOptions,
      focusedReport: null,
      minutesDurationFilter
    }
  },
  computed: {
    ...mapState({
      selectedDevice: state => state.devices.selectedDevice,
      isLoading: state => state.reports.isLoading,
      reports: state => state.reports.reports
    }),
    endDatetimeValidations () {
      return {
        minDatetime: this.startDate,
        maxDatetime: addHours(this.startDate, rangeInHoursForDeviceReports)
      }
    },
    showReports () {
      return !!this.reports?.length
    },
    disableGenerateReport () {
      return !this.startDate || !this.endDate
    }
  },
  methods: {
    ...mapActions({
      getReport: 'reports/getReport',
      deleteReport: 'reports/deleteReport',
      setShowDetails: 'reports/setShowDetails',
      setSelectReport: 'reports/setSelectReport',
      goToLocation: 'reports/goToLocation',
      setFocus: 'map/setFocus',
      setMarkerFocus: 'map/setMarkerFocus',
      removeMarkerFocus: 'map/removeMarkerFocus'
    }),
    getStyleReport (report) {
      return {
        'border-left-color': report.color
      }
    },
    onGetReport (startDate, endDate) {
      const payload = {
        startDate: dateToServer(startDate),
        endDate: dateToServer(endDate),
        devicesId: [`${this.selectedDevice.id}`]
      }
      this.getReport(payload)
        .then(
          ({ reportWithData }) => {
            if (!reportWithData) {
              toastService.show('No hay datos para ese rango de tiempo')
            }
          }
        )
    },
    onGetReportLastHour () {
      const now = new Date()
      this.startDate = addHours(now, -1)
      this.endDate = now
      this.onGetReport(this.startDate, this.endDate)
    },
    onGetReportToday () {
      this.startDate = startOfToday()
      this.endDate = endOfToday()
      this.onGetReport(this.startDate, this.endDate)
    },
    onGetReportYesterday () {
      const yesterday = addDays(new Date(), -1)
      this.startDate = startOfDay(yesterday)
      this.endDate = endOfDay(yesterday)
      this.onGetReport(this.startDate, this.endDate)
    },
    onGetReportForm () {
      const diff = differenceInHours(this.endDate, this.startDate)
      if (diff < 0) {
        toastService.error('Revisa el rango de fechas')
        return
      } else if (diff > rangeInHoursForDeviceReports) {
        toastService.error(`Sólo puedes generar un reporte de hasta ${rangeInHoursForDeviceReports} horas a la vez`)
        return
      }
      this.onGetReport(this.startDate, this.endDate)
      this.toggleOpenForm()
    },
    toggleOpenForm (value) {
      if (typeof value === 'boolean') {
        this.showForm = value
        return
      }
      this.showForm = !this.showForm
    },
    onSelecting (reportId, event) {
      this.setSelectReport({ reportId, selected: !!event })
    },
    toggleShowDetails (reportId) {
      const newShowDetails = !this.reports.find(report => report.id === reportId).showDetails
      this.setShowDetails({ reportId, showDetails: newShowDetails })
    },
    getReportName (reportId) {
      const deviceName = this.reports.find(report => report.id === reportId).deviceName
      return `Lugares ${deviceName}`
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~@/scss/variables/colors';

.detail-title {
  font-size: 10px;
  line-height: 12px;
  margin-bottom: 4px;
}

.detail-subtitle {
  font-size: 10px;
  line-height: 12px;
  margin-bottom: 4px;
  margin-top: 5px;
  font-weight: 700;
}

.report-btn {
  margin-right: 4px;
}

.container-report {
  display: flex;
  flex-direction: column;
  border: 1px solid $border-gray;
  border-left-style: solid;
  border-left-width: 10px;
  padding: 4px 8px;
  margin-bottom: 4px;
}

.report-header {
  justify-content: space-between;
  align-items: center;
}

.device-name {
  font-size: 12px;
  line-height: 14px;
  font-weight: 700;
}

.details {
  border-top: 1px solid $border-gray;
}

.detail-location {
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;

  &:hover {
    background: #eaeaea;
  }

  .detail-icon {
    height: 20px;
  }
}

.detail-text {
  font-size: 12px;
}

.detail-btn-location {
  background: none;
  border: 0;
  cursor: pointer;
  padding: 0;
  margin: 0;
}

.button-with-icon {
  display: flex;
  align-items: center;
}
</style>
