const state = () => ({
  error: false
})

const mutations = {
  setError: (state, error) => {
    state.error = error
  }
}

const actions = {
  notifyError: async ({ commit }, error) => {
    commit('setError', error)
  }
}

const getters = {
}

export default {
  state,
  mutations,
  actions,
  getters,
  namespaced: true
}
