// TODO: Create title
const show = (event) => {
  // console.log(event)
}

const hide = (event) => {
  // console.log(event)
}

export default {
  // When the bound element is inserted into the DOM...
  inserted: function (el) {
    // Focus the element
    el.addEventListener('mouseover', (event) => {
      show(event)
    })
    el.addEventListener('mouseout', (event) => {
      hide(event)
    })
  }
}
