<template lang="pug">
span.color-indicator(:style='{ backgroundColor: color }')
</template>

<script>
export default {
  props: {
    color: { type: String }
  }
}
</script>

<style lang="scss" scoped>
.color-indicator {
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  box-shadow: 0px 0px 0px 5px #0000000f;
}
</style>
