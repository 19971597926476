let componentInstance = null

export const openCreateLocationModal = async ({ props, parent, modal }) => {
  if (!componentInstance) {
    const component = await import(/* webpackChunkName: "create-location-form" */ './CreateLocationForm.vue')
    componentInstance = component.default
  }

  modal.open({
    component: componentInstance,
    parent,
    hasModalCard: true,
    trapFocus: true,
    canCancel: ['escape', 'x'],
    props
  })
}

export const onLocationDrawn = (latLng, componentInstance) => {
  openCreateLocationModal({
    modal: componentInstance.$buefy.modal,
    parent: componentInstance,
    props: {
      latLng,
      isModal: true
    }
  })
}

export const onLocationEdit = (locationUpdated, componentInstance) => {
  openCreateLocationModal({
    modal: componentInstance.$buefy.modal,
    parent: componentInstance,
    props: {
      locationUpdated
    }
  })
}
