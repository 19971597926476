<template lang="pug">
color-indicator(:color='deviceStatusColors[device.status]' v-if='device')
</template>

<script>
import ColorIndicator from '@/components/shared/ColorIndicator'
import { deviceStatusColors } from '@/constants/enums'

export default {
  components: { ColorIndicator },
  props: {
    device: { type: Object }
  },
  data () {
    return { deviceStatusColors }
  }
}
</script>
