<template lang="pug">
button.crosshair(@click='toggleFollowing' :class='{ active: !!followId }')
  svg.crosshair-icon(xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round")
    circle(cx="12" cy="12" r="10")
    line(x1="22" y1="12" x2="18" y2="12")
    line(x1="6" y1="12" x2="2" y2="12")
    line(x1="12" y1="6" x2="12" y2="2")
    line(x1="12" y1="22" x2="12" y2="18")
</template>

<script>
import { mapActions, mapState } from 'vuex'

export default {
  data () {
    return {}
  },
  computed: {
    ...mapState({
      selectedDevice: state => state.devices.selectedDevice,
      selectedDeviceDetails: state => state.devices.selectedDeviceDetails,
      detailsLoading: state => state.devices.detailsLoading,
      followId: state => state.map.followId
    })
  },
  watch: {
    selectedDevice (current, previous) {
      if (current && previous && current.id === previous.id) {
        this.expanded = true
      }
    }
  },
  methods: {
    ...mapActions({
      getDevices: 'devices/getDevices',
      setSelectedDevice: 'devices/setSelectedDevice',
      setCenter: 'map/setCenter',
      setZoom: 'map/setZoom',
      setFocus: 'map/setFocus',
      setFollowId: 'map/setFollowId'
    }),
    async toggleFollowing () {
      if (this.selectedDevice) {
        if (this.selectedDevice.id === this.followId) {
          await this.setFollowId(null)
        } else {
          await this.setFocus({ center: [...this.selectedDevice.latLng] })
          await this.setFollowId(this.selectedDevice.id)
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~@/scss/variables/colors';

.crosshair {
  background: $background-gray;
  border-radius: 5px;
  border: 1px solid $main-color;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: box-shadow 100ms ease-in-out;

  &.active .crosshair-icon {
    stroke: var(--mainColor1);
  }

  &:hover {
    box-shadow: 0px 0px 15px #dadada;
  }
}

.crosshair-icon {
  stroke: gray;
}
</style>
