import { commandStatusEnum } from '@/constants/enums'
import commandsService from '@/services/commands.service'

const state = () => ({
  isLoading: false,
  isSaving: false,
  isError: false,
  commands: [],
  savingLoading: false,
  error: null
})

const mutations = {
  setCommands: (state, commands) => {
    state.commands = commands
  },
  setCommand: (state, command) => {
    const currentCommand = state.commands.find(c => c.id === command.id)
    if (currentCommand) {
      Object.assign(currentCommand, command)
    }
  },
  appendCommands: (state, commands) => {
    state.commands.unshift(...commands)
  },
  setStatus: (state, { isLoading, isError }) => {
    state.isLoading = isLoading
    state.isError = isError
  },
  setSavingStatus: (state, { loading, error }) => {
    state.savingLoading = loading
    state.error = error
  }
}

const actions = {
  refreshCommands: async ({ state, commit }) => {
    commit('setStatus', { isLoading: true })
    const minId = state.commands.reduce((maxId, command) => command.id > maxId ? command.id : maxId, 0)
    const result = await commandsService.getAll({ minId })
    if (result) {
      commit('appendCommands', result)
      commit('setStatus', { isLoading: false })
    } else {
      commit('setStatus', { isLoading: false, isError: true })
    }
  },
  loadCommands: async ({ state, commit }, { append } = {}) => {
    commit('setStatus', { isLoading: true })
    const result = await commandsService.getAll()
    if (result) {
      commit('setCommands', result)
      commit('setStatus', { isLoading: false })
    } else {
      commit('setCommands', [])
      commit('setStatus', { isLoading: false, isError: true })
    }
  },
  update: async ({ commit }, command) => {
    commit('setCommand', command)
  },
  save: async ({ commit, dispatch }, command) => {
    try {
      commit('setSavingStatus', { loading: true })
      const result = await commandsService.save(command)
      commit('setSavingStatus', { loading: false })
      dispatch('refreshCommands')
      return result
    } catch (error) {
      commit('setSavingStatus', { loading: false, error })
    }
  }
}

const getters = {
  pendingCommands: state => {
    const pendingStatuses = [
      commandStatusEnum.PENDING,
      commandStatusEnum.QUEUED,
      commandStatusEnum.DISPATCHED,
      commandStatusEnum.CANCEL,
      commandStatusEnum.CLIENT_NO_EXISTS
    ]
    return state.commands.filter(command => pendingStatuses.includes(command.status))
  }
}

export default {
  state,
  mutations,
  actions,
  getters,
  namespaced: true
}
