<template lang="pug">
  div
    .control-group
      button +
      button -
</template>

<script>
export default {
}
</script>

<style lang="scss" scoped>
.control-group {
  margin-bottom: 10px;
}
</style>
