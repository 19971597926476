<template lang="pug">
.app-menu
  ul.menu-groups
    li.menu-group(v-if='!menu.length')
      .menu-title-container No tiene acceso a ningún módulo. Por favor comuníquese con su proveedor.
    li.menu-group(v-for='group in menu')
      .menu-title-container(:title='group.title')
        span.menu-title(:class='{ reduced: reducedMenu }') {{ group.title }}
      ul.navbar-container
        menu-item(v-for='menuItem in group.pages' :item='menuItem' :key='menuItem.name' :class='{ reduced: reducedMenu }')
//- b-navbar
//-   template(slot='brand')
//-     b-navbar-item(tag='router-link' :to="{ path: '/' }")
//-       .header
//-         img.header-image(src='../assets/icon.png')
//-         .header-title SAMM GPS
//-   template(slot='start')
//-     b-navbar-item(:href='menuItem.url' v-for='menuItem in menu' :key='menuItem.name') {{ menuItem.name }}
//-     b-navbar-dropdown(label='Info')
//-       b-navbar-item(href='#') About
</template>

<script>
import MenuItem from './MenuItem'
import menu from '../constants/menu'
import authMixin from '@/mixins/auth.mixin'

export default {
  components: { MenuItem },
  mixins: [authMixin],
  computed: {
    menu () {
      const filteredMenu = menu.filter(group => {
        group.pages = group.pages.filter(page => {
          return !page.meta || !page.meta.permission || this.can(page.meta.permission)
        })
        return !!group.pages.length
      })
      return filteredMenu
    },
    reducedMenu () {
      return this.$route.meta.reducedMenu
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~@/scss/variables/colors';
@import '~@/scss/variables/sizes';

.app-header {
  padding: 0 15px;
  display: flex;
  align-items: center;
  height: 60px;
}

.app-menu {
  color: white;
  // box-shadow: 0 1px 5px $shadow-over-light;
  // TODO: revisar esto cuando volvamos a ver el mapa
  // z-index: 1100; // required to fix over leaflet map
  display: flex;
  flex-direction: column;
  overflow: auto;
}

.menu-groups {
  padding: 0 10px;
}

.menu-group {
  background: $menu-accent-bg;
  margin-bottom: 10px;
  border-radius: $border-radius;
}

.menu-title-container {
  padding: 5px 10px;
}

.menu-title {
  background: $menu-accent-bg;
  font-size: 0.7em;
  border-radius: 15px;
  padding: 3px 10px;
  text-transform: uppercase;
  cursor: default;

  &.reduced {
    height: 12px;
    width: 24px;
    display: inline-block;
    overflow: hidden;
    color: transparent;
  }
}

</style>

<style lang="scss">
.navbar-container {
  display: flex;
  flex-direction: column;
}

</style>
