import toastService from '@/services/toast.service'
let readingSubscription = null

const subscribeReading = fn => {
  readingSubscription = fn
}

const state = () => ({
  reading: false,
  error: false
})

const mutations = {
  setReadingState: (state, reading) => {
    state.reading = reading
    state.error = false
  },
  setErrorState: (state, error) => {
    state.reading = false
    state.error = error
  }
}

const actions = {
  start: async ({ commit, dispatch }, user) => {
    try {
      const ndef = new window.NDEFReader()
      await ndef.scan()
      commit('setReadingState', true)
      ndef.addEventListener('readingerror', () => {
        toastService.error('No se puede leer, intenta con otra')
      })

      ndef.addEventListener('reading', (result) => {
        const { serialNumber } = result
        const tagNumber = parseInt('0x' + serialNumber.replaceAll(':', ''))
        readingSubscription(tagNumber)
      })
    } catch (error) {
      commit('setErrorState', true)
    }
    // readingSubscription(123456789)
  }
}

export default {
  state,
  mutations,
  actions,
  subscribeReading,
  namespaced: true
}
