import api from './index'

const getFileName = dbPath => dbPath && dbPath.split('_').filter((f, index) => index > 0).join('_')

const downloadZip = async headerId => {
  const response = await api({
    url: `files/zip/${headerId}`,
    method: 'GET',
    responseType: 'blob'
  })
  const fileName = 'temp.zip'
  const blob = new Blob([response.data])
  downloadBlob(blob, fileName)
}

const getFile = async (path, fileName, params) => {
  const queryString = Object.keys(params || {}).map(key => `${key}=${params[key]}`).join('&')
  const result = await api({
    url: `files/${path}/${fileName}?${queryString}`,
    method: 'GET',
    responseType: 'blob'
  })
  return result
}

const download = async (path, fileName, params) => {
  const result = await getFile(path, fileName, params)
  if (result) {
    const blob = new Blob([result])
    downloadBlob(blob, fileName)
  }
}

const downloadBlob = (blob, fileName) => {
  const url = window.URL.createObjectURL(blob)
  const link = document.createElement('a')
  link.href = url
  link.setAttribute('download', fileName)
  document.body.appendChild(link)
  link.click()
}

const previewPdf = async (path, fileName, params) => {
  const result = await getFile(path, fileName, params)
  // return blob ? window.URL.createObjectURL(blob) : null
  if (result) {
    var blob = new Blob([result], { type: 'application/pdf' })
    var blobURL = URL.createObjectURL(blob)
    window.open(blobURL)

    // return new Promise((resolve, reject) => {
    //   const reader = new FileReader()
    //   reader.readAsDataURL(blob)
    //   reader.onloadend = () => {
    //     const base64data = reader.result
    //     resolve(base64data)
    //   }
    // })
  }
  return null
}

const upload = file => {
  const formData = new FormData()
  formData.append('file', file)
  return api.post('files', formData, {
    headers: { 'Content-Type': 'multipart/form-data' }
  })
}

const uploadToBucket = async (file, folder) => {
  const result = await api.get('files/presigned-url', {
    params: {
      key: file.name,
      contentType: file.type,
      folder
    }
  })
  if (!result) return null
  const myHeaders = new Headers({ 'Content-Type': result.contentType })
  try {
    await fetch(result.url, {
      method: 'PUT',
      headers: myHeaders,
      body: file
    })
  } catch (error) {
    return null
  }
  return result.key
}

const getPreviewUrl = async key => {
  const result = await api.get('files/presigned-preview-url', {
    params: { key }
  })
  if (!result) return null

  const blob = await fetch(result.url, { headers: { 'content-type': result.contentType } }).then(res => { return res.blob() })

  var img = URL.createObjectURL(blob)
  return img
}

export default {
  download,
  previewPdf,
  downloadZip,
  getFileName,
  upload,
  uploadToBucket,
  getPreviewUrl
}
