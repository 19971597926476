<template lang="pug">
.property-list-container.flex-column(v-if='data' :class='containerClass')
  ul(v-if='typeList === typesEnum.list')
    li(v-for='(item, index) in propertiesToShow' :key='`${item.field}-${index}`')
      b {{ item.label }}:
      router-link.ml(v-if='item.linkTo' :to='item.linkTo(data, item)') {{ item.renderFn ? item.renderFn(data[item.field] || defaultValue, data) : (data[item.field] || defaultValue) }}
      span.ml(v-else) {{ item.renderFn ? item.renderFn(data[item.field] || defaultValue, data) : (data[item.field] || defaultValue) }}
  ul(v-if='typeList === typesEnum.tags')
    li.mb(v-for='(item, index) in propertiesToShow' :key='`${item.field}-${index}`')
      b-taglist(attached)
        b-tag(type='is-info is-dark' size="is-medium") {{ item.label }}
        b-tag(type='is-light' size="is-medium") {{ item.renderFn ? item.renderFn(data[item.field] || defaultValue, data) : (data[item.field] || defaultValue) }}
  b-table(v-if='typeList === typesEnum.table' :data='dataTable' :columns='tableColumnsDef' bordered striped narrowed mobile-cards :show-header='false')
  b-table(v-if='typeList === typesEnum.cards' :data='dataCards' :columns='propertiesToShow' bordered mobile-cards card-layout)
</template>

<script>
const typesEnum = { list: 'list', tags: 'tags', table: 'table', cards: 'cards' }
const defaultTypeList = typesEnum.list
const sizesEnum = { xsmall: 'x-small', small: 'small', normal: 'normal', large: 'large' }
const defaultSize = sizesEnum.normal
const tableColumnsDef = [
  { field: 'property', label: 'Property' },
  { field: 'value', label: 'Value' }
]

export default {
  props: {
    data: { type: Object, default: () => null },
    defaultValue: { type: String, default: '-' },
    properties: { type: Array, default: () => [] },
    type: { type: String, default: defaultTypeList },
    size: { type: String, default: defaultSize }
  },
  data () {
    return {
      typesEnum,
      tableColumnsDef
    }
  },
  computed: {
    propertiesToShow () {
      if (this.properties.length) {
        return this.properties.map(prop => ({
          ...prop,
          renderFn: prop.renderFn || (value => value)
        }))
      }
      return Object.keys(this.data).map(key => ({
        field: key,
        label: key,
        renderFn: value => value
      }))
    },
    typeList () {
      return Object.keys(typesEnum).includes(this.type) ? this.type : defaultTypeList
    },
    containerClass () {
      return `property-list-${this.size}`
    },
    dataTable () {
      return Object.keys(this.propertiesToShow || {}).map(key => {
        return {
          property: this.propertiesToShow[key].label,
          value: this.propertiesToShow[key].renderFn(this.data[this.propertiesToShow[key].field] || this.defaultValue)
        }
      })
    },
    dataCards () {
      return [
        Object.keys(this.propertiesToShow).reduce(
          (acc, key) => {
            return {
              ...acc,
              [this.propertiesToShow[key].field]: this.propertiesToShow[key].renderFn(this.data[this.propertiesToShow[key].field] || this.defaultValue)
            }
          },
          {}
        )
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~@/scss/variables/colors';
@import '~@/scss/variables/sizes';

.property-list-x-small {
  font-size: 10px
}
.property-list-small {
  font-size: 12px
}
.property-list-normal {
  font-size: 14px
}
.property-list-large {
  font-size: 16px
}

</style>
