<template lang="pug">
b-field(horizontal)
  b-checkbox(
    v-model='rawValue'
    :required='required'
    :readonly='readonly'
    @input='valueChanged'
  ) {{ label }}
</template>

<script>
export default {
  props: {
    label: { type: String },
    required: { type: Boolean },
    readonly: { type: Boolean },
    value: {}
  },
  data () {
    return {
      rawValue: this.value
    }
  },
  watch: {
    value () {
      this.rawValue = this.value
    }
  },
  methods: {
    valueChanged () {
      this.$emit('input', this.rawValue)
    }
  }
}
</script>
