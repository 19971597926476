import {
  clientsList,
  contractsList,
  ewsDashboard,
  inpointSchedules,
  inpointVisits,
  invoicesList,
  locations,
  reportInOutZones,
  reportSpeed,
  reportTypes,
  shippingShipments,
  adminDevices,
  map,
  rulesList
} from '@/router/routes'

const sat = {
  title: 'Alertas tempranas',
  pages: [ewsDashboard]
}

const contracts = {
  title: 'Contratos',
  pages: [
    contractsList,
    invoicesList
  ]
}

const reports = {
  title: 'Reportes',
  pages: [reportInOutZones, reportSpeed, reportTypes]
}

const locationsGroup = {
  title: 'Control de rondas',
  pages: [inpointVisits, locations, inpointSchedules]
}

const shipping = {
  title: 'Shipping',
  pages: [shippingShipments]
}

const administration = {
  title: 'Administración',
  pages: [clientsList, adminDevices, rulesList]
}

const monitor = {
  title: 'Monitor',
  pages: [map]
}

export default [
  monitor,
  administration,
  contracts,
  locationsGroup,
  reports,
  shipping,
  sat
  // ,
  // {
  //   url: '/map',
  //   title: 'Mapa',
  //   name: 'Mapa',
  //   icon: 'map'
  // },
  // {
  //   url: '#/resumen',
  //   title: 'Resumen',
  //   name: 'Resumen',
  //   icon: 'map-signs'
  // },
  // {
  //   url: null,
  //   title: null,
  //   name: 'Administrar',
  //   icon: null,
  //   isNew: false,
  //   items: [{
  //     url: '/clientes',
  //     title: null,
  //     name: 'Clientes',
  //     icon: 'user',
  //     isNew: false,
  //     forDist: true,
  //     items: null
  //   },
  //   {
  //     url: '/devices',
  //     name: 'Equipos',
  //     icon: 'laptop',
  //     title: null,
  //     isNew: false,
  //     forDist: false,
  //     items: null
  //   },
  //   {
  //     url: '/visores',
  //     name: 'Visores',
  //     icon: 'eye',
  //     title: null,
  //     isNew: false,
  //     forDist: false,
  //     items: null,
  //     buttons: [{
  //       url: '/visores/nuevo',
  //       title: 'Crear un visor',
  //       icon: 'plus'
  //     }
  //     ]
  //   },
  //   {
  //     url: '/sitios',
  //     name: 'Sitios',
  //     icon: 'map-marker',
  //     title: null,
  //     isNew: false,
  //     forDist: false,
  //     items: null,
  //     buttons: [{
  //       url: '/sitios/importar',
  //       title: 'Importar sitios',
  //       icon: 'upload'
  //     }
  //     ]
  //   },
  //   {
  //     url: '/conductores',
  //     title: null,
  //     name: 'Conductores',
  //     icon: 'id-card-o',
  //     isNew: true,
  //     forDist: false,
  //     items: null
  //   },
  //   {
  //     // url: '#logs',
  //     // title: null,
  //     name: 'Reglas',
  //     // icon: 'file-text-o',
  //     isNew: false,
  //     forDist: false,
  //     items: [{
  //       url: '/reglas/zonas',
  //       title: null,
  //       name: 'Reglas de zonas',
  //       icon: 'laptop',
  //       isNew: false,
  //       forDist: false,
  //       items: null
  //     }, {
  //       url: '/reglas/odometros',
  //       title: null,
  //       name: 'Reglas de odometros',
  //       icon: 'eye',
  //       isNew: false,
  //       forDist: false,
  //       items: null
  //     }, {
  //       url: '/reglas/por-tiempo',
  //       title: null,
  //       name: 'Reglas de tiempo',
  //       icon: 'calendar-o',
  //       isNew: false,
  //       forDist: false,
  //       items: null
  //     }, {
  //       url: '/reglas/por-uso',
  //       title: null,
  //       name: 'Uso del equipo',
  //       icon: 'clock-o',
  //       isNew: false,
  //       forDist: false,
  //       items: null
  //     }
  //     ]
  //   },
  //   {
  //     url: '/comparendos',
  //     title: null,
  //     name: 'Comparendos',
  //     icon: null,
  //     isNew: true,
  //     forDist: false,
  //     items: null
  //   }, {
  //     url: '/logs',
  //     title: null,
  //     name: 'Logs',
  //     icon: 'file-text-o',
  //     isNew: false,
  //     forDist: false,
  //     items: null,
  //     id: 1
  //   },
  //   {
  //     url: '/lineas',
  //     title: null,
  //     name: 'Líneas',
  //     icon: 'phone',
  //     isNew: false,
  //     forDist: true,
  //     items: null
  //   }]
  // },
  // {
  //   url: null,
  //   title: null,
  //   name: 'Informes',
  //   icon: null,
  //   isNew: false,
  //   items: [{
  //     url: '/sitios-visitados',
  //     title: null,
  //     name: 'Sitios visitados',
  //     icon: 'laptop',
  //     isNew: false,
  //     forDist: false,
  //     items: null
  //   },
  //   {
  //     url: '/reporte-velocidad',
  //     title: null,
  //     name: 'Reporte de velocidad',
  //     icon: 'eye',
  //     isNew: false,
  //     forDist: false,
  //     items: null
  //   },
  //   {
  //     url: '/reporte-actividad',
  //     title: null,
  //     name: 'Reporte de actividad',
  //     icon: 'road',
  //     isNew: false,
  //     forDist: false,
  //     items: null
  //   }
  //   ]
  // },
  // {
  //   url: null,
  //   title: null,
  //   name: 'Perfil',
  //   icon: null,
  //   isNew: false,
  //   forDist: false,
  //   items: [{
  //     url: '/perfil/cambiar-clave',
  //     title: null,
  //     name: 'Cambiar clave',
  //     icon: 'key',
  //     isNew: false,
  //     forDist: false,
  //     items: null
  //   }, {
  //     url: '/perfil/eventos-importantes',
  //     title: null,
  //     name: 'Eventos importantes',
  //     icon: 'globe',
  //     isNew: false,
  //     forDist: false,
  //     items: null
  //   }, {
  //     url: '/perfil/actividad',
  //     title: null,
  //     name: 'Actividad',
  //     icon: 'exchange',
  //     isNew: false,
  //     forDist: false,
  //     items: null
  //   }
  //   ]
  // },
  // {
  //   url: '/sugerencias',
  //   title: 'Ideas y sugerencias',
  //   name: null,
  //   icon: 'lightbulb-o',
  //   isNew: false,
  //   forDist: false,
  //   items: null,
  //   tagId: 'ideas'
  // }
]
