export const tileProviders = [
  {
    name: 'OpenTopoMap',
    visible: true,
    url: 'https://{s}.tile.opentopomap.org/{z}/{x}/{y}.png',
    attribution:
          "Map data: &copy; <a href='http://www.openstreetmap.org/copyright'>OpenStreetMap</a>, <a href='http://viewfinderpanoramas.org'>SRTM</a> | Map style: &copy; <a href='https://opentopomap.org'>OpenTopoMap</a> (<a href='https://creativecommons.org/licenses/by-sa/3.0/'>CC-BY-SA</a>)"
  },
  {
    name: 'Google Maps',
    visible: true,
    url: 'https://mt1.google.com/vt/lyrs=r&x={x}&y={y}&z={z}',
    attribution:
          "Map data: &copy; <a href='https://www.google.com/maps'>Google Maps</a>"
  },
  {
    name: 'Google Satellite',
    visible: true,
    url: 'https://www.google.cn/maps/vt?lyrs=s@189&gl=cn&x={x}&y={y}&z={z}',
    attribution: "Map data: &copy; <a href='https://www.google.com/maps'>Google Maps</a>"
  },
  {
    name: 'Google Hybrid',
    visible: true,
    url: 'https://mt1.google.com/vt/lyrs=y&x={x}&y={y}&z={z}',
    attribution:
          "Map data: &copy; <a href='https://www.google.com/maps'>Google Maps</a>"
  },
  {
    name: 'Google Traffic',
    visible: true,
    url: 'https://mt1.google.com/vt?lyrs=h@159000000,traffic|seconds_into_week:-1&style=3&x={x}&y={y}&z={z}',
    attribution:
          "Map data: &copy; <a href='https://www.google.com/maps'>Google Maps</a>"
  },
  {
    name: 'Google Roads',
    visible: true,
    url: 'https://mt1.google.com/vt/lyrs=h&x={x}&y={y}&z={z}',
    attribution:
          "Map data: &copy; <a href='https://www.google.com/maps'>Google Maps</a>"
  },
  {
    name: 'OpenStreetMap',
    visible: true,
    attribution: '&copy; <a target="_blank" href="http://osm.org/copyright">OpenStreetMap</a> contributors',
    url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
  }
]
