<template lang="pug">
input.input(:type='type' :id='id' @input='inputChanged' v-model='rawValue' :required='required' :disabled='disabled')
</template>

<script>
import { format } from 'date-fns'

export default {
  props: {
    value: {},
    required: { type: Boolean },
    disabled: { type: Boolean },
    id: { type: String },
    type: { type: String, default: 'date' }
  },
  data () {
    return {
      rawValue: null
    }
  },
  created () {
    this.setValue(this.value)
  },
  watch: {
    value: {
      inmediate: true,
      handler: function () {
        this.setValue(this.value)
      }
    }
  },
  methods: {
    setValue (value) {
      if (value) {
        if (this.type === 'date') {
          this.rawValue = value.split('T')[0]
        } else if (this.type === 'datetime-local') {
          const formatted = format(new Date(value), 'yyyy-MM-dd') + 'T' + format(new Date(value), 'HH:mm')
          this.rawValue = formatted
        } else {
          this.rawValue = value
        }
      } else {
        this.rawValue = value
      }
      this.emitChanges(this.formatOutput(value))
    },
    inputChanged ($event) {
      const value = this.formatOutput($event.target.value)
      this.emitChanges(value)
    },
    emitChanges (value) {
      this.$emit('input', value)
    },
    formatOutput (value) {
      const formattedValue = this.type === 'datetime-local' ? new Date(value).toISOString() : value
      return formattedValue === '' ? null : formattedValue
    }
  }
}
</script>
