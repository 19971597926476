import defaultIcon from '@/assets/icons/default.png'
import carIcon from '@/assets/icons/car.png'
import motoIcon from '@/assets/icons/moto.png'

export const getColoredArrowIcon = (color, rotationAngle = 0) => `
<svg width="26" height="32" style="transform: rotate(${rotationAngle}deg)" viewBox="0 0 26 32" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M12.9521 0L26 32L12.9522 22.6667L0 32L12.9521 0Z" fill="${color}"/>
</svg>
`

export const markerIcons = {
  null: { url: defaultIcon, isPointer: true },
  'car.png': { url: carIcon, iconSize: [38, 38], iconAnchor: [38 / 2, 38 / 2] },
  'moto.png': { url: motoIcon, iconSize: [38, 38], iconAnchor: [38 / 2, 38 / 2] },
  'blue.png': { colorIcon: '#0000FF', iconSize: [26, 32], iconAnchor: [26 / 2, 32 / 2] },
  'dark-blue.png': { colorIcon: '#00008B', iconSize: [26, 32], iconAnchor: [26 / 2, 32 / 2] },
  'dark-green.png': { colorIcon: '#006400', iconSize: [26, 32], iconAnchor: [26 / 2, 32 / 2] },
  'green.png': { colorIcon: '#008000', iconSize: [26, 32], iconAnchor: [26 / 2, 32 / 2] },
  'light-green.png': { colorIcon: '#90EE90', iconSize: [26, 32], iconAnchor: [26 / 2, 32 / 2] },
  'orange.png': { colorIcon: '#FFA500', iconSize: [26, 32], iconAnchor: [26 / 2, 32 / 2] },
  'purple.png': { colorIcon: '#800080', iconSize: [26, 32], iconAnchor: [26 / 2, 32 / 2] },
  'red-wine.png': { colorIcon: '#8B0000', iconSize: [26, 32], iconAnchor: [26 / 2, 32 / 2] },
  'red.png': { colorIcon: '#FF0000', iconSize: [26, 32], iconAnchor: [26 / 2, 32 / 2] },
  'sky-blue.png': { colorIcon: '#87CEEB', iconSize: [26, 32], iconAnchor: [26 / 2, 32 / 2] },
  'yellow.png': { colorIcon: '#FFFF00', iconSize: [26, 32], iconAnchor: [26 / 2, 32 / 2] }
}

export const clientsColumnDefs = [
  { sortable: true, filter: true, field: 'id', headerName: 'Id', type: 'link' },
  { sortable: true, filter: true, field: 'name', headerName: 'Nombre' },
  { sortable: true, filter: true, field: 'clientName', headerName: 'Cliente', type: 'link' },
  { sortable: true, filter: true, field: 'registerDate', headerName: 'Fecha de registro', type: 'datetime' }
]
