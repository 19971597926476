<template lang="pug">
.public-page
  .page-body
    slot
</template>

<script>
export default {}
</script>

<style lang="scss" scoped>
body,
html {
  height: 100%;
  overflow: hidden;
}

.public-page {
  display: flex;
  flex: 1;
}

.page-body {
  // background: #f3f3f3;
  flex: 1;
  overflow-y: auto;
}
</style>
