<template lang="pug">
.command-list
  div
    command-list-item(v-for='command in pendingCommands' :key='command.id' :command='command')
    i(v-if='pendingCommands.length === 0') No hay comandos en curso.
    hr
  div
    p.mb-3 Todos los comandos
    command-list-item(v-for='command in commands' :key='command.id' :command='command')
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex'
import CommandListItem from './CommandListItem'

export default {
  components: { CommandListItem },
  data () {
    return {}
  },
  created () {

  },
  computed: {
    ...mapGetters({
      pendingCommands: 'commands/pendingCommands'
    }),
    ...mapState({
      isLoading: state => state.commands.isLoading,
      isSaving: state => state.commands.isSaving,
      isError: state => state.commands.isError,
      commands: state => state.commands.commands
    })
  },
  methods: {
    ...mapActions({
      loadCommands: 'commands/loadCommands'
    })
  }
}
</script>

<style lang="scss" scoped>
.command-list {
  width: 100%;
  max-width: 500px;

  @media only screen and (min-width: 769px) {
    min-width: 500px;
  }
}
</style>
